import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeft, ChevronRight, Search, Calendar, X, PlusCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

function TableHeader({ sortConfig, onSort }) {
  return (
    <thead className="bg-black text-white">
      <tr>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold w-[20%]">
          <div className="flex items-center gap-2">
            Client Name
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold w-[15%]">
          <div className="flex items-center gap-2">
            Report ID
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold w-[15%]">
          <div className="flex items-center gap-2">
            <Calendar size={16} />
            Date
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold w-[15%]">
          <div className="flex items-center gap-2">
            Type
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold w-[15%]">
          <div className="flex items-center gap-2">
            Supervisor
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            Products Surveyed
          </div>
        </th>
      </tr>
    </thead>
  );
}

function ProductBadges({ products }) {
  const productList = [
    { name: 'Fire Extinguisher', condition: products.fireExtinguisher },
    { name: 'Fire Hydrant', condition: products.fireHydrant },
    { name: 'Hose Reel', condition: products.hoseReel },
    { name: 'Fire Alarm', condition: products.fireAlarm },
    { name: 'Fire Pump', condition: products.firePump },
    { name: 'Sprinkler', condition: products.sprinkler },
    { name: 'Gas Detector', condition: products.gasDetector },
    { name: 'PA System', condition: products.paSystem },
    { name: 'Rodent System', condition: products.rodentSystem },
    { name: 'Water Detector', condition: products.waterDetector },
    { name: 'Kitchen System', condition: products.kitchenSystem },
    { name: 'Room System', condition: products.roomSystem },
    { name: 'Tube System', condition: products.tubeSystem }
  ];

  return (
    <div className="flex flex-wrap gap-1 max-w-[200px] overflow-x-auto">
      {productList.map((product, index) => (
        product.condition && (
          <span key={index} className="px-2 py-1 bg-gray-100 text-black text-xs rounded-full font-['Montserrat'] whitespace-nowrap">
            {product.name}
          </span>
        )
      ))}
    </div>
  );
}

function TableRow({ survey, onClick }) {
  return (
    <motion.tr 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="border-b border-gray-200 hover:bg-gray-50 transition-colors cursor-pointer"
      onClick={onClick}
    >
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm font-medium">
          {survey.customerDetails?.client_name ?? 'Unknown Client'}
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm">{survey.report_id}</div>
      </td>
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm">{survey.date}</div>
      </td>
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm">
          {survey.type?.replace('_', ' ') ?? 'Unknown'}
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm">
          {survey.engineerName ?? 'No supervisor'}
        </div>
      </td>
      <td className="px-6 py-4">
        <ProductBadges products={survey.products} />
      </td>
    </motion.tr>
  );
}

export default function SurveyReports() {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState('all');
  const [startFilterDate, setStartFilterDate] = useState('');
  const [endFilterDate, setEndFilterDate] = useState('');
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchSurveys(selectedSurvey);
  }, [selectedSurvey, currentPage, startFilterDate, endFilterDate, searchQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const formatDateForServer = (inputDate) => {
    const [year, month, day] = inputDate.split('-');
    return `${day}-${month}-${year}`;
  }

  const fetchSurveys = async (surveyType) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
  
    const params = {
      surveysPerPage: 10,
      pageNumber: currentPage,
      type: surveyType !== 'all' ? surveyType : undefined,
      startDate: startFilterDate ? formatDateForServer(startFilterDate) : undefined,
      endDate: endFilterDate ? formatDateForServer(endFilterDate) : undefined,
      searchQuery: searchQuery || undefined,
    };
  
    try {
      const response = await axios.get(`${API_URL}/api/reports`, {
        headers: { Authorization: token },
        params,
      });

      setSurveys(response.data.reports);
      setTotalPages(response.data.totalPages);
      setTotalReports(response.data.totalReports);
      setFilteredSurveys(response.data.reports);

    } catch (error) {
      if (error.response?.status === 404) {
        setSurveys([]);
        setFilteredSurveys([]);
        setTotalPages(0);
        setTotalReports(0);
      } else if (error.response?.status === 403 || error.response?.status === 400) {
        alert('Session expired. You need to log in again.');
        setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/');
        }, 3000);
      } else {
        console.error('Error fetching surveys:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSurveyTypeClick = (surveyType) => {
    setSelectedSurvey(surveyType);
    setCurrentPage(1);
  };

  const handleStartDateChange = (e) => {
    const inputDate = e.target.value;
    const today = new Date().toISOString().split('T')[0];
    
    if (inputDate <= today) {
      setStartFilterDate(inputDate);
      if (inputDate && endFilterDate && new Date(inputDate) > new Date(endFilterDate)) {
        setEndFilterDate(inputDate);
      }
      setCurrentPage(1);
    }
  };

  const handleEndDateChange = (e) => {
    setEndFilterDate(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setSearchQuery(value);
      setCurrentPage(1);
    }
  };

  const clearFilter = () => {
    setStartFilterDate('');
    setEndFilterDate('');
    setSearchQuery('');
    setCurrentPage(1);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);

    if (endPage - startPage < 4) {
      startPage = Math.max(endPage - 4, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handleSurveySelect = (reportId) => {
    const selectedSurveyData = surveys.find(survey => survey.report_id === reportId);

    if (selectedSurveyData?.type) {
      const surveyType = selectedSurveyData.type;
      const routes = {
        'Basic_Survey': '/baseSurveys/basicSurvey',
        'Detailed_Survey': '/baseSurveys/detailedSurvey',
        'Form_B': '/baseSurveys/formB'
      };

      const route = routes[surveyType];
      if (route) {
        navigate(route, { state: { report_id: selectedSurveyData.report_id } });
      } else {
        console.warn('Unknown survey type:', surveyType);
      }
    } else {
      console.error('Survey type is missing or invalid for the selected report.');
    }
  };

  return (
    <div className="py-12 font-['Montserrat']">
      <div className="container mx-auto px-4 max-w-7xl">
        <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-12 text-black text-center"
        >
          Survey Reports
        </motion.h1>

        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-xl shadow-lg p-6 mb-8"
        >
          <div className="flex justify-between flex-wrap gap-4 mb-6">
            <div className="flex space-x-4">
              {['all', 'Basic_Survey', 'Detailed_Survey', 'Form_B'].map((type) => (
                <motion.button
                  key={type}
                  onClick={() => handleSurveyTypeClick(type)}
                  className={`px-6 py-3 rounded-full font-medium text-sm transition-all duration-300 ${
                    selectedSurvey === type
                      ? 'bg-black text-white shadow-lg'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </motion.button>
              ))}
            </div>

            <motion.button
              onClick={() => {
                const token = localStorage.getItem('token');
                if (!token) {
                  alert('Token not found. Please log in again.');
                  return;
                }
                
                const subdomainWindow = window.open('https://forms.safetysaarthi.in');
                
                if (!subdomainWindow) {
                  alert('Popup blocked. Please allow popups for this website.');
                  return;
                }
            
                // Wait for the subdomain to load before posting the message
                const checkSubdomainLoaded = setInterval(() => {
                  try {
                    if (subdomainWindow.location.hostname === 'forms.safetysaarthi.in') {
                      subdomainWindow.postMessage({ token }, 'https://forms.safetysaarthi.in');
                      clearInterval(checkSubdomainLoaded);
                    }
                  } catch (error) {
                    // Catch cross-origin errors until the subdomain is ready
                  }
                }, 500);
              }}
              className="bg-black text-white px-6 py-3 rounded-full font-medium text-sm hover:bg-gray-800 flex items-center shadow-lg whitespace-nowrap"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <PlusCircle size={18} className="mr-2" />
              Edit Forms
            </motion.button>

          </div>

          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="flex flex-wrap items-center gap-4">
              <div className="relative">
                <input
                  type="date"
                  value={startFilterDate}
                  onChange={handleStartDateChange}
                  className="px-4 py-3 pl-10 text-gray-700 bg-gray-100 border-0 rounded-full focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                  max={new Date().toISOString().split('T')[0]}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
              <div className="relative">
                <input
                  type="date"
                  value={endFilterDate}
                  onChange={handleEndDateChange}
                  className="px-4 py-3 pl-10 text-gray-700 bg-gray-100 border-0 rounded-full focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                  max={new Date().toISOString().split('T')[0]}
                  min={startFilterDate}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search by client or engineer name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full px-4 py-3 pl-10 text-gray-700 bg-gray-100 border-0 rounded-full focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
              <motion.button 
                onClick={clearFilter}
                className="px-4 py-2 bg-red-100 text-red-600 rounded-full hover:bg-red-200 transition-colors duration-300 flex items-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <X size={18} className="mr-2" />
                Clear Filter
              </motion.button>
            </div>
            <p className="text-black font-semibold">
              Total Reports: {totalReports}
            </p>
          </div>
        </motion.div>

        <AnimatePresence mode="wait">
          <motion.div
            key={selectedSurvey}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
              </div>
            ) : filteredSurveys.length > 0 ? (
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="">
                  <table className="w-full">
                    <TableHeader />
                    <tbody>
                      {filteredSurveys.map((survey) => (
                        <TableRow
                          key={survey.report_id}
                          survey={survey}
                          onClick={() => handleSurveySelect(survey.report_id)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                {totalPages > 1 && (
                  <div className="flex items-center justify-between px-6 py-4 bg-white border-t border-gray-200">
                    <div className="flex items-center gap-2">
                      <span className="text-sm">
                        Page {currentPage} of {totalPages}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="p-2 text-black hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronLeft size={18} />
                      </button>
                      {getPageNumbers().map((pageNumber) => (
                        <button
                          key={pageNumber}
                          onClick={() => setCurrentPage(pageNumber)}
                          className={`w-10 h-10 flex items-center justify-center rounded-full transition-colors duration-300 ${
                            currentPage === pageNumber
                              ? 'bg-black text-white'
                              : 'text-gray-600 hover:bg-gray-100'
                          }`}
                        >
                          {pageNumber}
                        </button>
                      ))}
                      <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="p-2 text-black hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronRight size={18} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center justify-center h-64 bg-white rounded-xl shadow-lg p-8"
              >
                <h2 className="text-2xl font-semibold text-gray-700 mb-2">No Reports Found</h2>
                <p className="text-gray-500 text-center max-w-md">
                  There are no survey reports matching your current filters. Try adjusting your search criteria.
                </p>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}