import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Search, Calendar, X, Download, PlusCircle, ChevronRight, ChevronLeft, CheckCircle, FileX, ChevronDown, ChevronUp, Pen } from 'lucide-react';

function TableHeader({ sortConfig, onSort }) {
  return (
    <thead className="bg-black text-white">
      <tr>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            Client Name
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            Report ID
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            Date
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            Type
          </div>
        </th>
        <th className="px-6 py-4 text-right font-['Montserrat'] text-sm font-semibold">Actions</th>
      </tr>
    </thead>
  );
}

function DownloadButton({ reportId, clientName, type }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadComplete, setIsDownloadComplete] = useState(false);

  const downloadPDF = async () => {
    setIsDownloading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/generate/documents/${type}/${reportId}`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${reportId}_${clientName}.pdf`;

      document.body.appendChild(link);
      link.click();
      link.remove();

      setIsDownloadComplete(true);
      setTimeout(() => setIsDownloadComplete(false), 3000);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <motion.button 
      className={`p-2 rounded-full transition-colors duration-300 focus:outline-none ${
        isDownloading ? 'bg-gray-300' : isDownloadComplete ? 'bg-green-500 text-white' : 'text-black hover:bg-gray-100'
      }`}
      onClick={downloadPDF}
      disabled={isDownloading}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {isDownloading ? (
        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-black"></div>
      ) : isDownloadComplete ? (
        <CheckCircle size={18} />
      ) : (
        <Download size={18} />
      )}
    </motion.button>
  );
}

function TableRow({ document, onEdit }) {
  const navigate = useNavigate();
  
  return (
    <motion.tr 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="border-b border-gray-200 hover:bg-gray-50 transition-colors cursor-pointer"
      // onClick={() => onEdit(document)}
    >
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm font-medium">{document.Client_Name ?? 'Unknown Client'}</div>
      </td>
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm">{document.report_id}</div>
      </td>
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm">{document.Date}</div>
      </td>
      <td className="px-6 py-4">
        <div className="font-['Montserrat'] text-sm">{document.type ?? 'Unknown'}</div>
      </td>
      <td className="px-6 py-4">
        <div className="flex items-center justify-end gap-2" onClick={e => e.stopPropagation()}>
          <DownloadButton 
            reportId={document.report_id} 
            clientName={document.Client_Name} 
            type={document.type} 
          />
          <motion.button
            onClick={(e) => {
              e.stopPropagation();
              onEdit(document);
            }}
            className="p-2 text-black hover:bg-gray-100 rounded-full"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Pen size={18} />
          </motion.button>
        </div>
      </td>
    </motion.tr>
  );
}

export default function DocumentsManager() {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState('All');
  const [startFilterDate, setStartFilterDate] = useState('');
  const [endFilterDate, setEndFilterDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchDocuments = async (documentType) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    const params = {
      documentsPerPage: 12,
      pageNumber: currentPage,
      type: documentType !== 'All' ? documentType : undefined,
      startDate: startFilterDate ? formatDateForServer(startFilterDate) : undefined,
      endDate: endFilterDate ? formatDateForServer(endFilterDate) : undefined,
      searchQuery: searchQuery || undefined,
    };

    try {
      const response = await axios.get(`${API_URL}/api/documents`, {
        headers: { Authorization: token },
        params,
      });

      setDocuments(response.data.documents);
      setTotalPages(response.data.totalPages);
      setTotalDocuments(response.data.totalDocuments);
      
    } catch (error) {
      console.error('Error fetching documents:', error);
      setDocuments([]);
      setTotalPages(0);
      setTotalDocuments(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocumentTypeClick = (documentType) => {
    setSelectedDocumentType(documentType);
    setCurrentPage(1);
  };

  const handleNewForm = () => {
    navigate('/documents/fill-documents');
  };

  const handleStartDateChange = (e) => {
    const inputDate = e.target.value;
    const today = new Date().toISOString().split('T')[0];
    
    if (inputDate <= today) {
      setStartFilterDate(inputDate);
      if (inputDate && endFilterDate && new Date(inputDate) > new Date(endFilterDate)) {
        setEndFilterDate(inputDate);
      }
      setCurrentPage(1);
    }
  };

  const handleEndDateChange = (e) => {
    setEndFilterDate(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setSearchQuery(value);
      setCurrentPage(1);
    }
  };

  const clearFilter = () => {
    setStartFilterDate('');
    setEndFilterDate('');
    setSearchQuery('');
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);

    if (endPage - startPage < 4) {
      startPage = Math.max(endPage - 4, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const formatDateForServer = (inputDate) => {
    const [year, month, day] = inputDate.split('-');
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    fetchDocuments(selectedDocumentType);
  }, [selectedDocumentType, currentPage, startFilterDate, endFilterDate, searchQuery]);

  const handleEditDocument = (document) => {
    navigate(`/documents/fill-documents`, { 
      state: { 
        type: document.type, 
        reportId: document.report_id 
      } 
    });
  };

  return (
    <div className="py-12 font-['Montserrat']">
      <div className="container mx-auto px-4 max-w-7xl">
        <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-12 text-black text-center"
        >
          Documents Manager
        </motion.h1>

        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-xl shadow-lg p-6 mb-8"
        >
          <div className="flex justify-between items-center mb-6">
            <div className="flex space-x-4">
              {['All', 'Form_A', 'Form_B'].map((type) => (
                <motion.button
                  key={type}
                  onClick={() => handleDocumentTypeClick(type)}
                  className={`px-6 py-3 rounded-full font-medium text-sm transition-all duration-300 ${
                    selectedDocumentType === type
                      ? 'bg-black text-white shadow-lg'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </motion.button>
              ))}
            </div>
            <motion.button
              onClick={handleNewForm}
              className="bg-black text-white px-6 py-3 rounded-full font-medium text-sm hover:bg-gray-800 flex items-center shadow-lg whitespace-nowrap"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <PlusCircle size={18} className="mr-2" />
              Create New Document
            </motion.button>
          </div>

          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="flex flex-wrap items-center gap-4">
              <div className="relative">
                <input
                  type="date"
                  value={startFilterDate}
                  onChange={handleStartDateChange}
                  className="px-4 py-3 pl-10 text-gray-700 bg-gray-100 border-0 rounded-full focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                  max={new Date().toISOString().split('T')[0]}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
              <div className="relative">
                <input
                  type="date"
                  value={endFilterDate}
                  onChange={handleEndDateChange}
                  className="px-4 py-3 pl-10 text-gray-700 bg-gray-100 border-0 rounded-full focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                  max={new Date().toISOString().split('T')[0]}
                  min={startFilterDate}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search by client or document name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full px-4 py-3 pl-10 text-gray-700 bg-gray-100 border-0 rounded-full focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>
              <motion.button 
                onClick={clearFilter}
                className="px-4 py-2 bg-red-100 text-red-600 rounded-full hover:bg-red-200 transition-colors duration-300 flex items-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <X size={18} className="mr-2" />
                Clear Filter
              </motion.button>
            </div>
            <p className="text-black font-semibold">
              Total Documents: {totalDocuments}
            </p>
          </div>
        </motion.div>

        <AnimatePresence mode="wait">
          <motion.div
            key={selectedDocumentType}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
              </div>
            ) : documents.length > 0 ? (
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="">
                  <table className="w-full">
                    <TableHeader />
                    <tbody>
                      {documents.map((document) => (
                        <TableRow
                          key={document.report_id}
                          document={document}
                          onEdit={handleEditDocument}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                {totalPages > 1 && (
                  <div className="flex items-center justify-between px-6 py-4 bg-white border-t border-gray-200">
                    <div className="flex items-center gap-2">
                      <span className="text-sm">
                        Page {currentPage} of {totalPages}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        className="p-2 text-black hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronLeft size={18} />
                      </button>
                      {getPageNumbers().map((pageNumber) => (
                        <button
                          key={pageNumber}
                          onClick={() => setCurrentPage(pageNumber)}
                          className={`w-10 h-10 flex items-center justify-center rounded-full transition-colors duration-300 ${
                            currentPage === pageNumber
                              ? 'bg-black text-white'
                              : 'text-gray-600 hover:bg-gray-100'
                          }`}
                        >
                          {pageNumber}
                        </button>
                      ))}
                      <button
                        onClick={goToNextPage}
                        disabled={currentPage === totalPages}
                        className="p-2 text-black hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronRight size={18} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center justify-center h-64 bg-white rounded-xl shadow-lg p-8"
              >
                <FileX size={48} className="text-gray-400 mb-4" />
                <h2 className="text-2xl font-semibold text-gray-700 mb-2">No Documents Found</h2>
                <p className="text-gray-500 text-center max-w-md">
                  There are no documents matching your current filters. Try adjusting your search criteria or create a new document.
                </p>
                <motion.button
                  onClick={handleNewForm}
                  className="mt-6 bg-black text-white px-6 py-3 rounded-full font-medium text-sm hover:bg-gray-800 flex items-center shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <PlusCircle size={18} className="mr-2" />
                  Create New Document
                </motion.button>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}