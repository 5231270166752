import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const FireHoseReel = ({ reels = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = reels.some(reel => reel.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Hose Reel Drum System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {reels.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                  <th className="p-3 text-sm font-semibold text-left">Location</th>
                  <th className="p-3 text-sm font-semibold text-left">Rubber Hose Condition</th>
                  <th className="p-3 text-sm font-semibold text-left">Operating Valve Condition</th>
                  <th className="p-3 text-sm font-semibold text-left">Shut Off Nozzle</th>
                  <th className="p-3 text-sm font-semibold text-left">Drum Movement</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {reels.map((reel, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{reel.Location || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Rubber_Hose_Cond || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Operating_Valve_Cond || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Shut_Off_Nozzle || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Drum_Movement || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Remark || 'NA'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }

      {newSystemExists && reels.length > 0 && (
        <>
          <CollapsibleHeading 
            title="New Recommendations of Fire Hose Reel System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                    <th className="p-3 text-sm font-semibold text-left">Hose Reel Drum</th>
                    <th className="p-3 text-sm font-semibold text-left">Hose Reel Drum Empty</th>
                    <th className="p-3 text-sm font-semibold text-left">Hose Reel Pipe</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {reels
                    .filter(reel => reel.New_System_Rec === 'Yes')
                    .flatMap((reel, reelIndex) => {
                      const newSystems = Array.isArray(reel.New_Systems) ? reel.New_Systems : [reel.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${reelIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{reelIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.hoseReel_Drum || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Empty_HRD || newSystem?.Other_Capacity || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.hoseReel_Pipe || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Remark || 'NA'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FireHoseReel;