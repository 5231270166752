import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const FireHoseReel = ({ reels = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Hose Reel Drum System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {reels.length > 0 &&
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                  <th className="p-3 text-sm font-semibold text-left">Location</th>
                  <th className="p-3 text-sm font-semibold text-left">Water Flow</th>
                  <th className="p-3 text-sm font-semibold text-left">Rubber Hose Integrity</th>
                  <th className="p-3 text-sm font-semibold text-left">Shut Off Nozzle</th>
                  <th className="p-3 text-sm font-semibold text-left">Operating Valve</th>
                  <th className="p-3 text-sm font-semibold text-left">Drum Movement</th>
                  <th className="p-3 text-sm font-semibold text-left">Hose Clamp</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {reels.map((reel, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{reel.Location || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Water_Flow || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Rubber_Hose_Int || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Shut_Off_Nozzle || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Operating_Valve || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Drum_Movement || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Hose_Clamp || 'NA'}</td>
                    <td className="p-3 text-sm border">{reel.Remark || 'NA'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  );
};

export default FireHoseReel;