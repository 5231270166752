import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const FireExtinguishers = ({ fireExtinguishers = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = fireExtinguishers.some(extinguisher => extinguisher.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Extinguishers System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {fireExtinguishers.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                  <th className="p-3 text-sm font-semibold text-left">Type</th>
                  <th className="p-3 text-sm font-semibold text-left">CT / SP</th>
                  <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                  <th className="p-3 text-sm font-semibold text-left">Quantity</th>
                  <th className="p-3 text-sm font-semibold text-left">Make</th>
                  <th className="p-3 text-sm font-semibold text-left">Manufacturing Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Last Maintained By</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {fireExtinguishers.map((extinguisher, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{extinguisher.Type || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Type_Ct_Sp || 'NA'}</td>
                    <td className="p-3 text-sm border">{`${extinguisher.Capacity} ${extinguisher.Capacity_Unit}` || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Quantity || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Make || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Manufacturing_Date || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Last_Maintained_By || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Remarks || 'NA'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }

      {newSystemExists && fireExtinguishers.length > 0 && (
        <>
          <CollapsibleHeading 
            title="New Recommendations of Fire Extinguishers" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Type</th>
                    <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                    <th className="p-3 text-sm font-semibold text-left">Quantity</th>
                    <th className="p-3 text-sm font-semibold text-left">Make</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {fireExtinguishers
                    .filter(extinguisher => extinguisher.New_System_Rec === 'Yes')
                    .flatMap((extinguisher, extinguisherIndex) => {
                      const newSystems = Array.isArray(extinguisher.New_Systems) ? extinguisher.New_Systems : [extinguisher.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${extinguisherIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{extinguisherIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Type || 'NA'}</td>
                          <td className="p-3 text-sm border">{`${newSystem?.Capacity} ${newSystem?.Capacity_Unit}` || newSystem?.Other_Capacity || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Quantity || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Make || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Remarks || 'NA'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FireExtinguishers;