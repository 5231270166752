import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import DownloadButton from "../../CommonFormComponents/downloadButton";
import Heading from "../../CommonFormComponents/Heading";
import CustomerDetails from "./CustomerDetails";

import FireExtinguishers from "./IndividualComponents/FireExtinguishers";
import FireHydrantSystem from "./IndividualComponents/FireHydrantSystem";
import FireHoseReel from "./IndividualComponents/FireHoseReel";

import FireAlarmDetectionSystem from "./IndividualComponents/FireAlarmDetectionSystem";
import FirePumpRoom from "./IndividualComponents/FirePumpRoom";
import FireSprinklerSystem from "./IndividualComponents/FireSprinklerSystem";
import GasLeakDetectorSystem from "./IndividualComponents/GasLeakDetectorSystem";
import KitchenSuppressionSystem from "./IndividualComponents/KitchenSuppressionSystem";
import PublicAddressSystem from "./IndividualComponents/PublicAddressSystem";
import WaterLeakDetectorSystem from "./IndividualComponents/WaterLeakDetectorSystem";
import TubeSuppressionSystem from "./IndividualComponents/TubeSuppressionSystem";
import RoomSuppressionSystem from "./IndividualComponents/RoomSuppressionSystem";
import RodentRepellentSystem from "./IndividualComponents/RodentRepellentSystem";

const AMCSurvey = () => {
  const location = useLocation();
  const { amcSurveyId, amcId } = location.state || {};
  const [survey, setSurvey] = useState();
  const [amcDetails, setAmcDetails] = useState(null);
  const [activeSystem, setActiveSystem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [currentReportIndex, setCurrentReportIndex] = useState(1); // Start from 1 since keys start at 1
  const [totalReports, setTotalReports] = useState(0);

  useEffect(() => {
    const fetchAmcDetails = async () => {
      if (!amcId) return;

      try {
        const response = await axios.get(`${API_URL}/api/amc/${amcId}`);
        const amcData = response.data.amc;
        setAmcDetails(amcData);

        // Calculate total reports by subtracting 2 (Current and Index keys)
        const reportCount = Object.keys(amcData.amc_reports || {}).length - 2;
        setTotalReports(reportCount);

        // Find the current report number from amcSurveyId
        const reportEntries = Object.entries(amcData.amc_reports || {});
        const currentEntry = reportEntries.find(([key, value]) => 
          key !== 'Current' && key !== 'Index' && value.reportId === amcSurveyId
        );
        
        if (currentEntry) {
          setCurrentReportIndex(parseInt(currentEntry[0]));
        }

      } catch (err) {
        console.error('Error fetching AMC details:', err);
        setError('Failed to fetch AMC details');
      }
    };

    fetchAmcDetails();
  }, [API_URL, amcId, amcSurveyId]);

  useEffect(() => {
    const fetchSurveyData = async () => {
      if (!amcSurveyId) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${API_URL}/api/amc/surveys/${amcSurveyId}`);
        setSurvey(response.data.report);
      } catch (err) {
        setError(err.message || 'Failed to fetch survey data');
        console.error('Error fetching survey data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSurveyData();
  }, [API_URL, amcSurveyId]);

  const navigateReport = async (direction) => {
    if (!amcDetails?.amc_reports) return;

    const newIndex = direction === 'next' 
      ? currentReportIndex + 1 
      : currentReportIndex - 1;

    // Get the report at the new index
    const nextReport = amcDetails.amc_reports[newIndex].reportId;
    
    if (nextReport && newIndex > 0 && newIndex <= totalReports) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/amc/surveys/${nextReport}`);
        setSurvey(response.data.report);
        setCurrentReportIndex(newIndex);
        setActiveSystem(null);
      } catch (err) {
        setError(err.message || 'Failed to fetch survey data');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg font-['Montserrat']">
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg font-['Montserrat']">
        <div className="text-center text-red-600 p-4">
          <h2 className="text-xl font-bold mb-2">Error</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!survey) {
    return (
      <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg font-['Montserrat']">
        <div className="text-center text-red-600 p-4">
          <h2 className="text-xl font-bold mb-2">No report Found</h2>
        </div>
      </div>
    );
  }

  const toggleSystem = (system) => {
    if(activeSystem === system) {
      setActiveSystem(null);
    } else {
      setActiveSystem(system);
    }
  };

  const renderButton = (condition, condition2, label, system) => {
    if (condition || condition2) {
      return (
        <button
          onClick={() => toggleSystem(system)}
          className={`px-4 py-2 border-2 border-black rounded-lg text-center font-bold transition-colors mr-2 mb-2 ${
            activeSystem === system
              ? 'bg-yellow-300 text-black'
              : 'bg-white text-black hover:bg-yellow-100'
          }`}
        >
          {label}
        </button>
      );
    }
    return null;
  };

  const renderActiveSystem = () => {
    switch (activeSystem) {
      case 'fireExtinguishers':
        return <FireExtinguishers extinguishers={survey.fireExtinguisher.extinguishers} files={survey.fireExtinguisher.files || survey.fireExtinguisher.Files || []} />;
      case 'fireHydrant':
        return <FireHydrantSystem hydrants={survey.fireHydrantSystem.hydrants} files={survey.fireHydrantSystem.files || survey.fireHydrantSystem.Files || []} />;
      case 'fireHoseReel':
        return <FireHoseReel reels={survey.hoseReel.reels} files={survey.hoseReel.files || survey.hoseReel.Files || []} />;
      case 'fireAlarm':
        return <FireAlarmDetectionSystem alarms={survey.fireAlarmDetectionSystem.alarms} files={survey.fireAlarmDetectionSystem.files || survey.fireAlarmDetectionSystem.Files || []} />;
      case 'firePump':
        return <FirePumpRoom pumps={survey.firePumpRoom.pumps} files={survey.firePumpRoom.files || survey.firePumpRoom.Files || []}/>;
      case 'fireSprinkler':
        return <FireSprinklerSystem sprinklers={survey.fireSprinklerSystem.sprinklers} files={survey.fireSprinklerSystem.files || survey.fireSprinklerSystem.Files || []}/>;
      case 'gasLeak':
        return <GasLeakDetectorSystem gases={survey.gasLeakDetectorSystem.gases} files={survey.gasLeakDetectorSystem.files || survey.gasLeakDetectorSystem.Files || []} />;
      case 'kitchenSuppression':
        return <KitchenSuppressionSystem kitchens={survey.kitchenSuppressionSystem.kitchens} files={survey.kitchenSuppressionSystem.files || survey.kitchenSuppressionSystem.Files || []} />;
      case 'publicAddress':
        return <PublicAddressSystem addresses={survey.publicAddressSystem.addresses} files={survey.publicAddressSystem.files || survey.publicAddressSystem.Files || []} />;
      case 'waterLeak':
        return <WaterLeakDetectorSystem devices={survey.waterLeakDetectorSystem.devices} files={survey.waterLeakDetectorSystem.files || survey.waterLeakDetectorSystem.Files || []} />;
      case 'roomSuppression':
        return <RoomSuppressionSystem rooms={survey.roomSuppressionSystem.rooms} files={survey.roomSuppressionSystem.files || survey.roomSuppressionSystem.Files || []} />;
      case 'tubeSuppression':
        return <TubeSuppressionSystem tubes={survey.tubeSuppressionSystem.tubes} files={survey.tubeSuppressionSystem.files || survey.tubeSuppressionSystem.Files || []} />;
      case 'rodentRepellent':
        return <RodentRepellentSystem rodents={survey.rodentRepellentSystem.rodents} files={survey.rodentRepellentSystem.files || survey.rodentRepellentSystem.Files || []} />;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg font-['Montserrat']">
      <div className="flex justify-between items-center mb-6">
        <Heading title='AMC Survey'/>
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigateReport('prev')}
            disabled={currentReportIndex <= 1}
            className={`p-2 rounded-full ${
              currentReportIndex <= 1
                ? 'text-gray-400 cursor-not-allowed' 
                : 'text-black hover:bg-gray-200'
            }`}
          >
            <ChevronLeft size={24} />
          </button>
          <span className="text-sm">
            Report {currentReportIndex} of {totalReports}
          </span>
          <button
            onClick={() => navigateReport('next')}
            disabled={currentReportIndex >= totalReports}
            className={`p-2 rounded-full ${
              currentReportIndex >= totalReports
                ? 'text-gray-400 cursor-not-allowed' 
                : 'text-black hover:bg-gray-200'
            }`}
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>

      <CustomerDetails survey={survey} amcDetails={amcDetails} />

      <br />

      <div className="my-4 flex flex-wrap justify-center">
        {renderButton(survey?.fireExtinguisher?.extinguishers?.length > 0, survey?.fireExtinguisher?.files?.length > 0, "Fire Extinguishers", 'fireExtinguishers')}
        {renderButton(survey?.fireHydrantSystem?.hydrants?.length > 0, survey?.fireHydrantSystem?.files?.length > 0,"Fire Hydrant System", 'fireHydrant')}
        {renderButton(survey?.hoseReel?.reels?.length > 0, survey?.hoseReel?.files?.length > 0, "Fire Hose Reel", 'fireHoseReel')}
        {renderButton(survey?.fireAlarmDetectionSystem?.alarms?.length > 0, survey?.fireAlarmDetectionSystem?.files?.length > 0, "Fire Alarm Detection System", 'fireAlarm')}
        {renderButton(survey?.firePumpRoom?.pumps?.length > 0, survey?.firePumpRoom?.files?.length > 0, "Fire Pump Room", 'firePump')}
        {renderButton(survey?.fireSprinklerSystem?.sprinklers?.length > 0, survey?.fireSprinklerSystem?.files?.length > 0, "Fire Sprinkler System", 'fireSprinkler')}
        {renderButton(survey?.gasLeakDetectorSystem?.gases?.length > 0, survey?.gasLeakDetectorSystem?.files?.length > 0, "Gas Leak Detector System", 'gasLeak')}
        {renderButton(survey?.kitchenSuppressionSystem?.kitchens?.length > 0, survey?.kitchenSuppressionSystem?.files?.length > 0, "Kitchen Suppression System", 'kitchenSuppression')}
        {renderButton(survey?.publicAddressSystem?.addresses?.length > 0, survey?.publicAddressSystem?.files?.length > 0, "Public Address System", 'publicAddress')}
        {renderButton(survey?.waterLeakDetectorSystem?.devices?.length > 0, survey?.waterLeakDetectorSystem?.files?.length > 0, "Water Leak Detector System", 'waterLeak')}
        {renderButton(survey?.roomSuppressionSystem?.rooms?.length > 0, survey?.roomSuppressionSystem?.files?.length > 0, "Room Suppression System", 'roomSuppression')}
        {renderButton(survey?.tubeSuppressionSystem?.tubes?.length > 0, survey?.tubeSuppressionSystem?.files?.length > 0, "Tube Suppression System", 'tubeSuppression')}
        {renderButton(survey?.rodentRepellentSystem?.rodents?.length > 0, survey?.rodentRepellentSystem?.files?.length > 0, "Rodent Repellent System", 'rodentRepellent')}
      </div>

      {renderActiveSystem()}

      <div className="flex justify-center mt-8">
        <DownloadButton 
          reportId={survey.report_id} 
          clientName={survey.customerDetails?.client_name} 
          type="amc_survey" 
        />
      </div>
    </div>
  );
};

export default AMCSurvey;