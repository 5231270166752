import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const FireExtinguishers = ({ extinguishers = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Extinguishers System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {extinguishers.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                  <th className="p-3 text-sm font-semibold text-left">Location</th>
                  <th className="p-3 text-sm font-semibold text-left">Type</th>
                  <th className="p-3 text-sm font-semibold text-left">Ct / Sp</th>
                  <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                  <th className="p-3 text-sm font-semibold text-left">Last Refilled On</th>
                  <th className="p-3 text-sm font-semibold text-left">Next Refilling Date</th>
                  <th className="p-3 text-sm font-semibold text-left">HPT Due On</th>
                  <th className="p-3 text-sm font-semibold text-left">Pressure Gauge</th>
                  <th className="p-3 text-sm font-semibold text-left">Hose Pipe</th>
                  <th className="p-3 text-sm font-semibold text-left">Nozzle Horn</th>
                  <th className="p-3 text-sm font-semibold text-left">Safety Pin</th>
                  <th className="p-3 text-sm font-semibold text-left">Body Sticker</th>
                  <th className="p-3 text-sm font-semibold text-left">Body Belt</th>
                  <th className="p-3 text-sm font-semibold text-left">Valve</th>
                  <th className="p-3 text-sm font-semibold text-left">Body Condition</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {extinguishers.map((extinguisher, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{extinguisher.Location || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Type || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Type_Ct_Sp || 'NA'}</td>
                    <td className="p-3 text-sm border">{`${extinguisher.Capacity} ${extinguisher.Capacity_Unit}` || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Last_Refill_Date || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Next_Refill_Date || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.HPT_Due_Date || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Pressure_Gauge || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Hose_Pipe || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Nozzle_Horn || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Safety_Pin || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Body_Sticker || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Body_Belt || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Valve || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Body_Condition || 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Remark || 'NA'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  );
};

export default FireExtinguishers;