import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const FireHydrantSystem = ({ hydrants = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Hydrant System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />} 
      {hydrants.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                  <th className="p-3 text-sm font-semibold text-left">Location</th>
                  <th className="p-3 text-sm font-semibold text-left">Water Pressure</th>
                  <th className="p-3 text-sm font-semibold text-left">Latch</th>
                  <th className="p-3 text-sm font-semibold text-left">Gland Nut</th>
                  <th className="p-3 text-sm font-semibold text-left">Hyd. Cap</th>
                  <th className="p-3 text-sm font-semibold text-left">Coupling Washer</th>
                  <th className="p-3 text-sm font-semibold text-left">Short Branch Pipe</th>
                  <th className="p-3 text-sm font-semibold text-left">Rubber Bending</th>
                  <th className="p-3 text-sm font-semibold text-left">Key</th>
                  <th className="p-3 text-sm font-semibold text-left">Key Glass</th>
                  <th className="p-3 text-sm font-semibold text-left">Hose Box Glass</th>
                  <th className="p-3 text-sm font-semibold text-left">Hose box Condition</th>
                  <th className="p-3 text-sm font-semibold text-left">Hose Pipe</th>
                  <th className="p-3 text-sm font-semibold text-left">Hammer</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {hydrants.map((hydrant, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{hydrant?.Location || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Water_Pressure || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Latch || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Gland_Nut || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Hyd_Cap || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Coupling_Washer || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Short_Branch_Pipe || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Rubber_Bending || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Key || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Key_Glass || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Hose_Box_Glass || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Hose_Box_Condition || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Hose_Pipe || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.Hammer || 'NA'}</td>
                    <td className="p-3 text-sm border">{hydrant?.remark || 'NA'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  );
};

export default FireHydrantSystem;