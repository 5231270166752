import React from "react";

const CustomerDetails = ({ survey, amcDetails }) => {
  if (!survey || !amcDetails) return null;

  return (
    <div className="space-y-6 mb-6">
      {/* Buyer Information */}
      <section>
        <h3 className="text-xl font-bold mb-4 font-['Montserrat'] border-b border-gray-200 pb-2">
          Buyer Information
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-3">
            <div className="flex">
              <span className="w-1/3 text-gray-600 text-sm">Company Name:</span>
              <span className="w-2/3 text-sm font-medium">{amcDetails.Buyer?.Name || 'NA'}</span>
            </div>
            <div className="flex">
              <span className="w-1/3 text-gray-600 text-sm">Address:</span>
              <span className="w-2/3 text-sm font-medium">{amcDetails.Buyer?.Address || 'NA'}</span>
            </div>
            {/* <div className="flex">
              <span className="w-1/3 text-gray-600 text-sm">GST:</span>
              <span className="w-2/3 text-sm font-medium">{amcDetails.Buyer?.GST || 'NA'}</span>
            </div> */}
          </div>
          <div className="space-y-3">
            {amcDetails.Buyer?.Contacts?.map((contact, index) => (
              <div key={index} className="bg-gray-50 p-3 rounded-lg">
                <p className="font-medium text-sm">{contact.Name}</p>
                <p className="text-sm text-gray-600">{contact.Designation}</p>
                <p className="text-sm text-gray-600">{contact.Email}</p>
                <p className="text-sm text-gray-600">{contact.Phone}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Survey Information */}
      <section>
        <h3 className="text-xl font-bold mb-4 font-['Montserrat'] border-b border-gray-200 pb-2">
          Survey Details
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-3">
            <div className="flex">
              <span className="w-1/3 text-gray-600 text-sm">Report No:</span>
              <span className="w-2/3 text-sm font-medium">{survey.report_id}</span>
            </div>
            <div className="flex">
              <span className="w-1/3 text-gray-600 text-sm">Date:</span>
              <span className="w-2/3 text-sm font-medium">{survey.date}</span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex">
              <span className="w-1/3 text-gray-600 text-sm">Supervisor:</span>
              <span className="w-2/3 text-sm font-medium">{survey.engineerName || 'NA'}</span>
            </div>
            <div className="flex">
              <span className="w-1/3 text-gray-600 text-sm">Contact:</span>
              <span className="w-2/3 text-sm font-medium">{survey.engineerContact || 'NA'}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomerDetails;