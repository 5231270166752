import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { User, Phone, Mail, Briefcase, Building2, AtSign, Hash, MapPin, Calendar, Shield } from 'lucide-react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const decodedToken = jwtDecode(token);
        const emp_id = decodedToken.emp_id;

        const response = await axios.get(`${API_URL}/api/profileData`, {
          params: { emp_id },
          headers: { authorization: `${token}` }
        });

        setUserData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data. Please try again later.');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#f8f9fa]">
        <div className="relative w-24 h-24">
          <motion.div
            className="absolute border-4 border-black rounded-full w-full h-full"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [1, 0.5, 1],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
          <motion.div
            className="absolute border-4 border-gray-300 rounded-full w-full h-full"
            animate={{
              scale: [1.2, 1, 1.2],
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#f8f9fa]">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white p-8 rounded-2xl shadow-lg text-center font-['Montserrat']"
        >
          <div className="text-3xl mb-4">😕</div>
          <div className="text-xl text-gray-800 font-medium">{error}</div>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-4 px-6 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
          >
            Try Again
          </button>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="py-12 px-4 font-['Montserrat']">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="grid md:grid-cols-[325px_1fr] gap-8"
        >
          {/* Left Column - Profile Card */}
          <motion.div
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-2xl shadow-lg p-8 h-fit"
          >
            <div className="text-center">
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="relative inline-block"
              >
                <div className="w-32 h-32 mx-auto rounded-full overflow-hidden border-4 border-gray-100 shadow-lg">
                  <img
                    src={`https://ui-avatars.com/api/?name=${userData.name}&background=random&size=200`}
                    alt={userData.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <span className="absolute bottom-2 right-2 w-4 h-4 bg-green-500 rounded-full border-2 border-white"></span>
              </motion.div>
              <h1 className="mt-4 text-2xl font-bold text-gray-900">{userData.name}</h1>
              <p className="text-gray-600 mt-1">{userData.role}</p>
              <div className="mt-4 flex items-center justify-center space-x-2">
                <span className="px-3 py-1 bg-gray-100 rounded-full text-sm font-medium text-gray-800">
                  {userData.Department}
                </span>
              </div>
            </div>
            
            <div className="mt-8 space-y-4">
              <InfoItem icon={<Mail />} label="Email" value={userData.email_id} />
              <InfoItem icon={<Phone />} label="Phone" value={userData.contactNum} />
              <InfoItem icon={<Hash />} label="Employee ID" value={userData.employee_id} />
            </div>
          </motion.div>

          {/* Right Column - Detailed Information */}
          <motion.div
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.3 }}
            className="space-y-6"
          >
            {/* Professional Information */}
            <Section title="Professional Information">
              <div className="grid md:grid-cols-2 gap-6">
                <DetailCard
                  icon={<Briefcase />}
                  title="Designation"
                  value={userData.Designation}
                  subtitle="Current Position"
                />
                <DetailCard
                  icon={<Building2 />}
                  title="Department"
                  value={userData.Department}
                  subtitle="Working Division"
                />
                <DetailCard
                  icon={<Shield />}
                  title="Role"
                  value={userData.role}
                  subtitle="Access Level"
                />
                <DetailCard
                  icon={<AtSign />}
                  title="Username"
                  value={userData.emp_id}
                  subtitle="Login Credential"
                />
              </div>
            </Section>

            {/* Quick Actions */}
            {/* <Section title="Quick Actions">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {['Edit Profile', 'Change Password', 'Security', 'Preferences'].map((action, index) => (
                  <motion.button
                    key={action}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="p-4 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow text-center"
                  >
                    <span className="block text-sm font-medium text-gray-900">{action}</span>
                  </motion.button>
                ))}
              </div>
            </Section> */}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white rounded-2xl shadow-lg p-6"
  >
    <h2 className="text-xl font-bold text-gray-900 mb-6">{title}</h2>
    {children}
  </motion.div>
);

const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-center space-x-3 text-gray-600">
    <div className="w-5 h-5">{icon}</div>
    <div className="flex-1 truncate">
      <div className="text-sm text-gray-500">{label}</div>
      <div className="font-medium text-gray-900 truncate">{value || 'Not provided'}</div>
    </div>
  </div>
);

const DetailCard = ({ icon, title, value, subtitle }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="bg-gray-50 p-4 rounded-xl border border-gray-100"
  >
    <div className="flex items-center space-x-3">
      <div className="p-2 bg-white rounded-lg shadow-sm">
        {icon}
      </div>
      <div>
        <div className="text-sm text-gray-500">{title}</div>
        <div className="font-semibold text-gray-900">{value || 'Not provided'}</div>
        <div className="text-xs text-gray-500 mt-1">{subtitle}</div>
      </div>
    </div>
  </motion.div>
);

export default Profile;