import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, ChevronLeft, ChevronRight, BarChartIcon as ChartColumn, File, User, ShieldCheck, Building2 } from 'lucide-react';
import { jwtDecode } from 'jwt-decode';
import Logout from './Logout';

// Profile Dropdown Component
function ProfileDropdown({ isOpen, setIsOpen, setIsLoggedIn, userRole, isSmallScreen }) {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const profileButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  const handleProfileClick = () => {
    setIsOpen(false);
    navigate('/profile');
  };

  const renderNavLink = (to, icon, text) => (
    <NavLink
      to={to}
      onClick={() => setIsOpen(false)}
      className={({ isActive }) =>
        `flex items-center space-x-3 p-3 rounded-lg transition-all duration-200 ${
          isActive
            ? 'bg-gray-900 text-white'
            : 'text-gray-600 hover:bg-gray-100'
        }`
      }
    >
      {icon}
      <span className="font-['Montserrat']">{text}</span>
    </NavLink>
  );

  return (
    <div className="relative">
      <button
        ref={profileButtonRef}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-200"
      >
        <User size={20} className="text-gray-600" />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl py-1 z-50 border border-gray-100"
          >
            {isSmallScreen && (
              <div className="px-2 py-2 border-b border-gray-100">
                <div className="space-y-1">
                  {userRole === 'Manager' && (
                    <>
                      {renderNavLink('/dashboard', <ChartColumn size={20} />, 'Dashboard')}
                      {renderNavLink('/baseSurveys', <FileText size={20} />, 'Surveys')}
                      {renderNavLink('/documents', <File size={20} />, 'Documents')}
                    </>
                  )}
                  {renderNavLink('/amc', <ShieldCheck size={20} />, 'AMC')}
                  {renderNavLink('/nbc', <Building2 size={20} />, 'NBC')}
                </div>
              </div>
            )}
            <button
              onClick={handleProfileClick}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-200 font-['Montserrat']"
            >
              <User size={20} className="inline-block mr-2" />
              Profile
            </button>
            <div className="p-4 border-t border-gray-100">
              <Logout setIsLoggedIn={setIsLoggedIn} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

// Sidebar Component
function Sidebar({ isSidebarOpen, toggleSidebar, userRole, isSmallScreen }) {
  const renderNavLink = (to, icon, text) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center rounded-lg transition-all duration-200 group relative ${
          isActive
            ? 'bg-gray-900 text-white shadow-lg'
            : 'text-gray-600 hover:bg-gray-100'
        }`
      }
    >
      <div className="flex items-center p-3 w-[48px] justify-center">
        {icon}
      </div>
      <motion.span
        initial={{ opacity: 0, width: 0 }}
        animate={{ 
          opacity: isSidebarOpen ? 1 : 0,
          width: isSidebarOpen ? 'auto' : 0
        }}
        transition={{ duration: 0.2 }}
        className="font-['Montserrat'] whitespace-nowrap overflow-hidden"
      >
        {text}
      </motion.span>
      {!isSidebarOpen && (
        <div className="absolute left-full ml-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 pointer-events-none whitespace-nowrap transition-opacity duration-200">
          {text}
        </div>
      )}
    </NavLink>
  );

  if (isSmallScreen) return null;

  return (
    <motion.aside 
      initial={false}
      animate={{ width: isSidebarOpen ? 256 : 80 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="bg-white fixed top-16 left-0 h-[calc(100vh-4rem)] flex flex-col shadow-lg z-40 font-['Montserrat']"
    >
      <div className="flex-grow p-4 space-y-2 overflow-hidden">
        <div className="space-y-2">
          {userRole === 'Manager' && (
            <>
              {renderNavLink('/dashboard', <ChartColumn size={20} />, 'Dashboard')}
              {renderNavLink('/baseSurveys', <FileText size={20} />, 'Surveys')}
              {renderNavLink('/documents', <File size={20} />, 'Documents')}
            </>
          )}
          {renderNavLink('/amc', <ShieldCheck size={20} />, 'AMC')}
          {renderNavLink('/nbc', <Building2 size={20} />, 'NBC')}
        </div>
      </div>
      <button
        onClick={toggleSidebar}
        className="absolute -right-3 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white p-1.5 rounded-full shadow-lg hover:bg-black focus:outline-none focus:ring-2 focus:ring-gray-600 transition-all duration-200 z-50"
      >
        {isSidebarOpen ? (
          <ChevronLeft size={16} />
        ) : (
          <ChevronRight size={16} />
        )}
      </button>
    </motion.aside>
  );
}

// Main NavbarSidebar Component
export default function NavbarSidebar({ setIsLoggedIn, isSidebarOpen, toggleSidebar }) {
  const [token] = useState(localStorage.getItem('token'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserRole(decodedToken.role);
    }

    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, [token]);

  return (
    <>
      <nav className="w-full bg-white fixed top-0 left-0 z-50 h-16 flex items-center justify-between px-6 shadow-lg font-['Montserrat']">
        <div className="flex items-center space-x-4">
          <img src="/logo.jpg" alt="Company Logo" className="w-12 h-11" />
          <h1 className="text-xl font-bold text-gray-900">Safety सारथी</h1>
        </div>
        <ProfileDropdown 
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          setIsLoggedIn={setIsLoggedIn}
          userRole={userRole}
          isSmallScreen={isSmallScreen}
        />
      </nav>

      <Sidebar 
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        userRole={userRole}
        isSmallScreen={isSmallScreen}
      />
    </>
  );
}