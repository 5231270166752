import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const RoomSuppressionSystem = ({ rooms = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Room Suppression System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {rooms.length > 0 &&
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">Sr. No</th>
                <th className="p-3 text-sm font-semibold text-left">Application Area</th>
                <th className="p-3 text-sm font-semibold text-left">Type of System</th>
                <th className="p-3 text-sm font-semibold text-left">Make of Cylinder</th>
                <th className="p-3 text-sm font-semibold text-left">Capacity of Cylinder</th>
                <th className="p-3 text-sm font-semibold text-left">Last Refilled Date</th>
                <th className="p-3 text-sm font-semibold text-left">Last HPT Date</th>
                <th className="p-3 text-sm font-semibold text-left">Make of Gas Release Panel</th>
                <th className="p-3 text-sm font-semibold text-left">Net weight</th>
                <th className="p-3 text-sm font-semibold text-left">Empty Body Weight</th>
                <th className="p-3 text-sm font-semibold text-left">SD Qty</th>
                <th className="p-3 text-sm font-semibold text-left">HD Qty</th>
                <th className="p-3 text-sm font-semibold text-left">MD Qty</th>
                <th className="p-3 text-sm font-semibold text-left">Abort MCP</th>
                <th className="p-3 text-sm font-semibold text-left">Release MCP</th>
                <th className="p-3 text-sm font-semibold text-left">Hooter</th>
                <th className="p-3 text-sm font-semibold text-left">Overall Working Condition of System</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {rooms.map((room, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{room.App_Area || '---'}</td>
                  <td className="p-3 text-sm border">{room.Type || room.Other_Type || '---'}</td>
                  <td className="p-3 text-sm border">{room.Make_Of_Cyl || '---'}</td>
                  <td className="p-3 text-sm border">{room.Cap_Of_Cyl || '---'}</td>
                  <td className="p-3 text-sm border">{room.Last_Refilled_Date || '---'}</td>
                  <td className="p-3 text-sm border">{room.Last_Hpt_Date || '---'}</td>
                  <td className="p-3 text-sm border">{room.Make_Of_Panel || '---'}</td>
                  <td className="p-3 text-sm border">{room.Net_Wt || '---'}</td>
                  <td className="p-3 text-sm border">{room.Empty_Body_Wt || '---'}</td>
                  <td className="p-3 text-sm border">{room.SD_Qty || '---'}</td>
                  <td className="p-3 text-sm border">{room.HD_Qty || '---'}</td>
                  <td className="p-3 text-sm border">{room.MD_Qty || '---'}</td>
                  <td className="p-3 text-sm border">{room.Abort_MCP || '---'}</td>
                  <td className="p-3 text-sm border">{room.Release_MCP || '---'}</td>
                  <td className="p-3 text-sm border">{room.Hooter || '---'}</td>
                  <td className="p-3 text-sm border">{room.Working_Cond === 'No' ? room.Remark : room.Working_Cond}</td>
                  <td className="p-3 text-sm border">{room.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      }
    </div>
  );
};

export default RoomSuppressionSystem;