import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const GasLeakDetectorSystem = ({ gases = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Gas Leak Detector System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {gases.length > 0 &&
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                <th className="p-3 text-sm font-semibold text-left">Location</th>
                <th className="p-3 text-sm font-semibold text-left">Type of Gas</th>
                <th className="p-3 text-sm font-semibold text-left">Make of the System</th>
                <th className="p-3 text-sm font-semibold text-left">Integration with Gas Shut Off Valve</th>
                <th className="p-3 text-sm font-semibold text-left">Address</th>
                <th className="p-3 text-sm font-semibold text-left">Panel Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Cable Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Clean</th>
                <th className="p-3 text-sm font-semibold text-left">Overall Working Condition of System</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {gases.map((gas, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{gas.Location || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Gas_Type || gas.Other_Gas_Type || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Make || '---'}</td>
                  <td className="p-3 text-sm border">
                    {gas.Int_With_Valve === 'Yes' ? gas.Int_With_Valve_Remarks || 'NA' : gas.Int_With_Valve || '---'}
                  </td>
                  <td className="p-3 text-sm border">{gas.Address || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Panel_Cond || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Cable_Cond || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Clean || '---'}</td>
                  <td className="p-3 text-sm border">
                    {gas.Working_Cond === 'No' ? gas.Remark || 'NA' : gas.Working_Cond || '---'}
                  </td>
                  <td className="p-3 text-sm border">{gas.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      }
    </div>
  );
};

export default GasLeakDetectorSystem;