import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Trash2, Loader, Search, Calendar, MapPin, Package, Hash, Ruler, MessageSquare } from 'lucide-react';
import { useNotification } from '../NotificationProvider';

const unitOptions = ['Nos', 'Kg', 'Ltr', 'Mtr'];

const FormA = ({reportId}) => {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [date, setDate] = useState('');
  const [equipments, setEquipments] = useState([{ id: Date.now(), Equipment: '', Quantity: '', Unit: '', Remarks: 'Installation Done' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFetchingData, setIsFetchingData] = useState(false);

  const navigate = useNavigate();
  const { showNotification } = useNotification();

  // Keeping all the existing useEffect and handler functions unchanged
  useEffect(() => {
    if (reportId) {
      fetchExistingData();
    }
  }, [reportId]);

  useEffect(() => {
    const fetchClients = async () => {
      if (searchTerm.length > 2) {
        try {
          // const response = await axios.get(`${API_URL}/api/clients/list?search=${searchTerm}`);
          // setClients(response.data);
        } catch (error) {
          console.error('Error fetching clients:', error);
        }
      } else {
        setClients([]);
      }
    };

    fetchClients();
  }, [searchTerm, API_URL]);

  const fetchExistingData = async () => {
    setIsFetchingData(true);
    try {
      const response = await axios.get(`${API_URL}/api/documents/${reportId}`);
      const { Client_Name, Client_Address, Date, Equipments } = response.data;
      setSelectedClient(Client_Name);
      setSearchTerm(Client_Name);
      setClientAddress(Client_Address);
      setDate(formatDateForInput(Date));
      setEquipments(Equipments.map(eq => ({ ...eq, id: Math.random() })));
    } catch (error) {
      console.error('Error fetching existing data:', error);
    } finally {
      setIsFetchingData(false);
    }
  };

  const handleAddEquipment = () => {
    setEquipments([...equipments, { id: Date.now(), Equipment: '', Quantity: '', Unit: '', Remarks: 'Installation Done' }]);
  };

  const handleEquipmentChange = (id, field, value) => {
    setEquipments(equipments.map(eq => eq.id === id ? { ...eq, [field]: value } : eq));
  };

  const handleDeleteEquipment = (id) => {
    if (equipments.length <= 1) {
      showNotification('At least one equipment is required', 'error');
      return;
    }
    setEquipments(equipments.filter(eq => eq.id !== id));
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (equipments.length === 0) {
      showNotification('Please add at least one equipment', 'error');
      return;
    }
    setIsLoading(true);

    const formData = {
      Client_Name: selectedClient || searchTerm,
      Client_Address: clientAddress,
      Date: formatDate(date) || undefined,
      Equipments: equipments.filter(eq => eq.Equipment && eq.Quantity && eq.Unit)
    };

    if (reportId) {
      formData.report_id = reportId;
    }

    try {
      const res = await axios.post(`${API_URL}/api/documents/formA`, {
        formData
      });
    
      if(res.status === 200) {
        showNotification('Form submitted successfully', 'success');
        navigate('/documents');
      }
    } catch (err) {
      console.error('Error submitting Form A:', err);
      showNotification('Failed to submit form', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchingData) {
    return (
      <div className="flex justify-center items-center h-screen font-['Montserrat']">
        <Loader className="animate-spin h-8 w-8 text-black" />
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="w-full mx-auto p-6 font-['Montserrat']"
    >
      <h1 className="text-3xl font-bold text-center mb-8 text-black">
        {reportId ? 'Edit Form A' : 'Form A'}
      </h1>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <div className="md:col-span-3">
            <label htmlFor="client" className="block text-sm font-medium text-gray-700 mb-2">Client Name *</label>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                id="client"
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setSelectedClient('');
                }}
                placeholder="Search for a client or enter new client name"
                required
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors duration-200"
              />
              <AnimatePresence>
                {clients.length > 0 && (
                  <motion.ul
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute z-10 w-full bg-white border border-gray-200 rounded-lg mt-1 max-h-60 overflow-auto shadow-lg"
                  >
                    {clients.map((client, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: index * 0.05 }}
                        onClick={() => {
                          setSelectedClient(client.name);
                          setSearchTerm(client.name);
                          setClients([]);
                        }}
                        className="p-3 hover:bg-gray-50 cursor-pointer transition-colors duration-150 ease-in-out border-b last:border-b-0"
                      >
                        {client.name}
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </div>
          </div>

          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-2">Date</label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors duration-200"
              />
            </div>
          </div>
        </div>

        <div className="relative">
          <label htmlFor="clientAddress" className="block text-sm font-medium text-gray-700 mb-2">Client Address *</label>
          <div className="relative">
            <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              id="clientAddress"
              value={clientAddress}
              onChange={(e) => setClientAddress(e.target.value)}
              placeholder="Enter complete client address"
              required
              className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors duration-200"
            />
          </div>
        </div>

        <div className="bg-gray-50 p-6 rounded-xl">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">Equipment Details</h2>

          <AnimatePresence>
            {equipments.map((eq, index) => (
              <motion.div
                key={eq.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`grid grid-cols-1 md:grid-cols-12 gap-4 items-start ${
                  index !== equipments.length - 1 ? 'mb-6 pb-6 border-b border-gray-200' : ''
                }`}
              >
                <div className="md:col-span-4">
                  <label htmlFor={`equipment-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-2">
                    <Package className="inline-block mr-2 h-4 w-4" />
                    Equipment *
                  </label>
                  <input
                    id={`equipment-${eq.id}`}
                    type="text"
                    value={eq.Equipment}
                    onChange={(e) => handleEquipmentChange(eq.id, 'Equipment', e.target.value)}
                    placeholder="Enter equipment name"
                    required
                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors duration-200"
                  />
                </div>
                
                <div className="md:col-span-2">
                  <label htmlFor={`quantity-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-2">
                    <Hash className="inline-block mr-2 h-4 w-4" />
                    Quantity *
                  </label>
                  <input
                    id={`quantity-${eq.id}`}
                    type="number"
                    value={eq.Quantity}
                    onChange={(e) => handleEquipmentChange(eq.id, 'Quantity', e.target.value)}
                    placeholder="0"
                    required
                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors duration-200"
                  />
                </div>

                <div className="md:col-span-2">
                  <label htmlFor={`unit-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-2">
                    <Ruler className="inline-block mr-2 h-4 w-4" />
                    Unit *
                  </label>
                  <select
                    id={`unit-${eq.id}`}
                    value={eq.Unit}
                    onChange={(e) => handleEquipmentChange(eq.id, 'Unit', e.target.value)}
                    required
                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors duration-200 appearance-none"
                  >
                    <option value="">Select</option>
                    {unitOptions.map((unit) => (
                      <option key={unit} value={unit}>{unit}</option>
                    ))}
                  </select>
                </div>

                <div className="md:col-span-3">
                  <label htmlFor={`remarks-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-2">
                    <MessageSquare className="inline-block mr-2 h-4 w-4" />
                    Remarks
                  </label>
                  <input
                    id={`remarks-${eq.id}`}
                    type="text"
                    value={eq.Remarks}
                    onChange={(e) => handleEquipmentChange(eq.id, 'Remarks', e.target.value)}
                    placeholder="Add remarks"
                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-black focus:border-black transition-colors duration-200"
                  />
                </div>

                <div className="md:col-span-1 flex items-end justify-center h-full">
                  <motion.button
                    type="button"
                    onClick={() => handleDeleteEquipment(eq.id)}
                    className="p-2 text-red-500 hover:text-red-600 focus:outline-none"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <Trash2 size={20} />
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        <div className="flex justify-center space-x-4">
          <motion.button
            type="button"
            onClick={handleAddEquipment}
            className="w-1/4 px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200 flex items-center justify-center space-x-2"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Plus size={20} />
            <span>Add Equipment</span>
          </motion.button>

          <motion.button
            type="submit"
            disabled={isLoading}
            className={`w-1/4 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center justify-center space-x-2 ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isLoading && <Loader className="animate-spin h-5 w-5" />}
            <span>{isLoading ? 'Submitting...' : (reportId ? 'Update Form' : 'Submit Form')}</span>
          </motion.button>
        </div>
      </form>
    </motion.div>
  );
};

export default FormA;