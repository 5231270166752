import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { X, FileText, ChevronDown, ChevronUp, Pen } from 'lucide-react';
import { AMCReportsList } from './AMCReportsList';
import axios from "axios";

function ContactsList({ contacts = [], title }) {
  const [showAll, setShowAll] = useState(false);
  const displayedContacts = showAll ? contacts : contacts.slice(0, 3);

  if (!contacts.length) return null;

  return (
    <div className="space-y-3">
      <h3 className="text-lg font-semibold text-gray-800 font-['Montserrat']">{title}</h3>
      <div className="space-y-3">
        {displayedContacts.map((contact, index) => (
          <div key={index} className="bg-gray-50 p-3 rounded-lg">
            <p className="font-medium text-sm">{contact.Name}</p>
            <p className="text-sm text-gray-600">{contact.Designation}</p>
            <p className="text-sm text-gray-600">{contact.Email}</p>
            <p className="text-sm text-gray-600">{contact.Phone}</p>
          </div>
        ))}
      </div>
      {contacts.length > 3 && (
        <button
          onClick={() => setShowAll(!showAll)}
          className="flex items-center text-sm text-gray-600 hover:text-black transition-colors"
        >
          {showAll ? (
            <>
              <ChevronUp className="mr-1" size={16} />
              Show Less
            </>
          ) : (
            <>
              <ChevronDown className="mr-1" size={16} />
              Show More
            </>
          )}
        </button>
      )}
    </div>
  );
}

export default function AMCDetailsDialog({ amcId, isOpen, onClose, onEdit }) {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [amc, setAmc] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAMCData = async () => {
      if (!amcId || !isOpen) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${API_URL}/api/amc/${amcId}`);
        setAmc(response.data.amc);
      } catch (err) {
        setError(err.message || 'Failed to fetch AMC data');
        console.error('Error fetching AMC data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAMCData();
  }, [API_URL, amcId, isOpen]);

  if (!isOpen) return null;

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-2xl p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-2xl p-8 max-w-md">
          <h2 className="text-xl font-bold text-red-600 mb-2">Error</h2>
          <p className="text-gray-600">{error}</p>
          <button
            onClick={onClose}
            className="mt-4 bg-black text-white px-6 py-2 rounded-full text-sm font-medium hover:bg-gray-800"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  if (!amc) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden"
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className="bg-black text-white p-6 relative">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-2xl font-bold font-['Montserrat']">{amc.AMC_Name}</h2>
                <p className="text-gray-300 mt-1 font-['Montserrat']">AMC ID: {amc.amc_id}</p>
              </div>
              <div className="flex items-center gap-3">
                <button
                  onClick={onEdit}
                  className="bg-white text-black px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-100 transition-colors flex items-center gap-2"
                >
                  <Pen size={16} />
                  Edit
                </button>
                <button
                  onClick={onClose}
                  className="text-white hover:text-gray-200 transition-colors"
                >
                  <X size={24} />
                </button>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="overflow-y-auto p-6 max-h-[calc(90vh-5rem)]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Left Column */}
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-bold mb-4 font-['Montserrat'] border-b border-gray-200 pb-2">
                    Buyer Information
                  </h3>
                  <div className="space-y-3">
                    {Object.entries(amc.Buyer).map(([key, value]) => (
                      key !== 'Contacts' && (
                        <div key={key} className="flex">
                          <span className="w-1/3 text-gray-600 text-sm">{key.replace('_', ' ')}:</span>
                          <span className="w-2/3 text-sm font-medium">{value || 'N/A'}</span>
                        </div>
                      )
                    ))}
                  </div>
                  <div className="mt-4">
                    <ContactsList contacts={amc.Buyer.Contacts} title="Buyer Contacts" />
                  </div>
                </section>
              </div>

              {/* Right Column */}
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-bold mb-4 font-['Montserrat'] border-b border-gray-200 pb-2">
                    Consignee Information
                  </h3>
                  <div className="space-y-3">
                    {Object.entries(amc.Consignee).map(([key, value]) => (
                      key !== 'Contacts' && (
                        <div key={key} className="flex">
                          <span className="w-1/3 text-gray-600 text-sm">{key.replace('_', ' ')}:</span>
                          <span className="w-2/3 text-sm font-medium">{value || 'N/A'}</span>
                        </div>
                      )
                    ))}
                  </div>
                  <div className="mt-4">
                    <ContactsList contacts={amc.Consignee.Contacts} title="Consignee Contacts" />
                  </div>
                </section>
              </div>
            </div>

            {/* AMC Details Section */}
            <section className="mt-8">
              <h3 className="text-xl font-bold mb-4 font-['Montserrat'] border-b border-gray-200 pb-2">
                AMC Details
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-3">
                  <div className="flex">
                    <span className="w-1/3 text-gray-600 text-sm">Initialized:</span>
                    <span className="w-2/3 text-sm font-medium">{amc.date}</span>
                  </div>
                  <div className="flex">
                    <span className="w-1/3 text-gray-600 text-sm">Status:</span>
                    <span className="w-2/3 text-sm font-medium">{amc.Status}</span>
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="flex">
                    <span className="w-1/3 text-gray-600 text-sm">Duration:</span>
                    <span className="w-2/3 text-sm font-medium">{amc.AMC_Duration}</span>
                  </div>
                  <div className="flex">
                    <span className="w-1/3 text-gray-600 text-sm">Frequency:</span>
                    <span className="w-2/3 text-sm font-medium">{amc.AMC_Frequency}</span>
                  </div>
                </div>
              </div>
            </section>

            {/* Services Section */}
            {amc.Selected_Services?.length > 0 && (
              <section className="mt-8">
                <h3 className="text-xl font-bold mb-4 font-['Montserrat'] border-b border-gray-200 pb-2">
                  Scope of Services
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                  {amc.Selected_Services.map((service, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 p-3 rounded-lg text-sm"
                    >
                      {service}
                    </div>
                  ))}
                </div>
              </section>
            )}

            {/* Contract Button */}
            {amc.Uploaded_File && (
              <div className="mt-8 flex justify-center">
                <button
                  onClick={() => window.open(amc.Uploaded_File, '_blank')}
                  className="bg-black text-white px-6 py-3 rounded-full text-sm font-medium hover:bg-gray-800 transition-colors flex items-center gap-2"
                >
                  <FileText size={16} />
                  View Contract
                </button>
              </div>
            )}

            {/* AMC Reports List */}
            <AMCReportsList 
              amcReports={amc.amc_reports} 
              clientName={amc.AMC_Name}
              amcId={amc.amc_id}
            />

            <br />
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
