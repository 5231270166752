import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';

const variants = {
  initial: { 
    opacity: 0, 
    x: 300,
    y: 0
  },
  animate: { 
    opacity: 1, 
    x: 0,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  },
  exit: { 
    opacity: 0, 
    x: 300,
    transition: {
      duration: 0.2,
      ease: 'easeIn'
    }
  }
};

const getColors = (type) => {
  switch (type) {
    case 'success':
      return 'bg-green-500';
    case 'error':
      return 'bg-red-500';
    default:
      return 'bg-green-500';
  }
};

export const Notification = ({ message, type, onClose, id }) => {
  return (
    <motion.div
      layout
      key={id}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      className="flex items-center bg-white rounded-lg shadow-lg overflow-hidden max-w-sm w-full"
    >
      <div className={`w-1.5 self-stretch ${getColors(type)}`} />
      <div className="flex-1 flex items-center p-4">
        <p className="text-gray-800 font-['Montserrat'] flex-1 mr-2">{message}</p>
        <button
          onClick={onClose}
          className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
        >
          <X size={16} className="text-gray-500" />
        </button>
      </div>
    </motion.div>
  );
};