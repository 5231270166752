import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const FirePumpRoom = ({ pumps = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = pumps.some(pump => pump.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Pump Room System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {pumps.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                  <th className="p-3 text-sm font-semibold text-left">Suction Type</th>
                  <th className="p-3 text-sm font-semibold text-left">Underground/ Modular Water Tank Capacity</th>
                  <th className="p-3 text-sm font-semibold text-left">Overhead Water Tank Capacity</th>
                  <th className="p-3 text-sm font-semibold text-left">Pump Type</th>
                  <th className="p-3 text-sm font-semibold text-left">Make</th>
                  <th className="p-3 text-sm font-semibold text-left">Model</th>
                  <th className="p-3 text-sm font-semibold text-left">Serial No</th>
                  <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                  <th className="p-3 text-sm font-semibold text-left">Pump Mode</th>
                  <th className="p-3 text-sm font-semibold text-left">Overall Working Condition of System</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {pumps.map((pump, index) => {
                  const pumpTypes = pump.Pump_Types || [];
                  const pumpsCount = Math.max(pumpTypes.length, 1);
                  return (
                    <React.Fragment key={index}>
                      <tr className="hover:bg-gray-50 transition-colors duration-150">
                        <td className="p-3 text-sm border" rowSpan={pumpsCount}>{index + 1}</td>
                        <td className="p-3 text-sm border" rowSpan={pumpsCount}>{pump.Suction_Type || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={pumpsCount}>{`${pump.UG_Type || ''}\n${pump.Under_Tank_Cap || '---'}`}</td>
                        <td className="p-3 text-sm border" rowSpan={pumpsCount}>{`${pump.OV_Type || ''}\n${pump.Over_Tank_Cap || '---'}`}</td>
                        <td className="p-3 text-sm border">{pumpTypes[0]?.Type || '---'}</td>
                        <td className="p-3 text-sm border">{pumpTypes[0]?.Make || '---'}</td>
                        <td className="p-3 text-sm border">{pumpTypes[0]?.Model || '---'}</td>
                        <td className="p-3 text-sm border">{pumpTypes[0]?.Serial_No || '---'}</td>
                        <td className="p-3 text-sm border">{pumpTypes[0]?.Capacity && pumpTypes[0]?.Capacity_Unit ? `${pumpTypes[0].Capacity} ${pumpTypes[0].Capacity_Unit}` : '---'}</td>
                        <td className="p-3 text-sm border">{pumpTypes[0]?.Pump_Mode || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={pumpsCount}>{pump.Working_Cond === 'No' ? pump.Remark : pump.Working_Cond}</td>
                        <td className="p-3 text-sm border" rowSpan={pumpsCount}>{pump.Write_Remarks || '---'}</td>
                      </tr>
                      {pumpTypes.slice(1).map((pumpType, pumpIndex) => (
                        <tr key={`${index}-${pumpIndex}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{pumpType.Type || '---'}</td>
                          <td className="p-3 text-sm border">{pumpType.Make || '---'}</td>
                          <td className="p-3 text-sm border">{pumpType.Model || '---'}</td>
                          <td className="p-3 text-sm border">{pumpType.Serial_No || '---'}</td>
                          <td className="p-3 text-sm border">{pumpType.Capacity && pumpType.Capacity_Unit ? `${pumpType.Capacity} ${pumpType.Capacity_Unit}` : '---'}</td>
                          <td className="p-3 text-sm border">{pumpType.Pump_Mode || '---'}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      }

      {newSystemExists && pumps.length > 0 && (
        <>
          <CollapsibleHeading 
             
            title="New Recommendations for Fire Pump Room System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                    <th className="p-3 text-sm font-semibold text-left">Suction Type</th>
                    <th className="p-3 text-sm font-semibold text-left">New Underground Capacity</th>
                    <th className="p-3 text-sm font-semibold text-left">New Overhead Capacity</th>
                    <th className="p-3 text-sm font-semibold text-left">Pump Type</th>
                    <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {pumps
                    .filter(pump => pump.New_System_Rec === 'Yes')
                    .flatMap((pump, pumpIndex) => {
                      const newSystems = Array.isArray(pump.New_Systems) ? pump.New_Systems : [pump.New_Systems].filter(Boolean);
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${pumpIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{pumpIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Suction_Type || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Under_Tank_Cap ? `${newSystem.Under_Tank_Cap} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Over_Tank_Cap ? `${newSystem.Over_Tank_Cap} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Type || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Capacity && newSystem?.Capacity_Unit ? `${newSystem.Capacity} ${newSystem.Capacity_Unit}` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FirePumpRoom;