import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const FireAlarmDetectionSystem = ({ alarms = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = alarms.some(alarm => alarm.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Alarm Detection System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {alarms.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                  <th className="p-3 text-sm font-semibold text-left">Location</th>
                  <th className="p-3 text-sm font-semibold text-left">Make</th>
                  <th className="p-3 text-sm font-semibold text-left">Model</th>
                  <th className="p-3 text-sm font-semibold text-left">Type</th>
                  <th className="p-3 text-sm font-semibold text-left">No. of Loops / Zones</th>
                  <th className="p-3 text-sm font-semibold text-left">Device</th>
                  <th className="p-3 text-sm font-semibold text-left">Quantity</th>
                  <th className="p-3 text-sm font-semibold text-left">Overall Working Condition of System</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {alarms.map((alarm, index) => {
                  const deviceTypes = alarm.Type === 'Conventional' 
                    ? (alarm.Conventional?.Device_Types || [])
                    : (alarm.Addressable?.Device_Types || []);
                  const devicesCount = Math.max(deviceTypes.length, 1);
                  return (
                    <React.Fragment key={index}>
                      <tr className="hover:bg-gray-50 transition-colors duration-150">
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Sr_No || index + 1}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Location || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Alarm_Make || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Alarm_Model_No || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Type || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>
                          {alarm.Type === 'Conventional' 
                            ? (alarm.Conventional?.Alarm_No_Of_Zones || '---')
                            : (alarm.Addressable?.Alarm_No_Of_Loops || '---')}
                        </td>
                        <td className="p-3 text-sm border">{deviceTypes[0]?.Device_Name || '---'}</td>
                        <td className="p-3 text-sm border">{deviceTypes[0]?.Quantity || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Working_Cond === 'No' ? alarm.Remark : alarm.Working_Cond}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Write_Remarks || '---'}</td>
                      </tr>
                      {deviceTypes.slice(1).map((device, deviceIndex) => (
                        <tr key={`${index}-${deviceIndex}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{device.Device_Name || '---'}</td>
                          <td className="p-3 text-sm border">{device.Quantity || '---'}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      }

      {newSystemExists && alarms.length > 0 && (
        <>
          <CollapsibleHeading 
            title="New Recommendations for Fire Alarm Detection System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">Length of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Width of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Height of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {alarms
                    .filter(alarm => alarm.New_System_Rec === 'Yes')
                    .flatMap((alarm, alarmIndex) => {
                      const newSystems = Array.isArray(alarm.New_Systems) ? alarm.New_Systems : [alarm.New_Systems].filter(Boolean);
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${alarmIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{alarmIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Length_Of_Premise ? `${newSystem.Length_Of_Premise} m` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Width_Of_Premise ? `${newSystem.Width_Of_Premise} m` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Height_Of_Premise ? `${newSystem.Height_Of_Premise} m` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FireAlarmDetectionSystem;