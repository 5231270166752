import React, { useState, useEffect } from 'react';
import axios from 'axios';

function NBCTable() {
  const [occupancies, setOccupancies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [subconditions, setSubconditions] = useState([]);
  const [installation, setInstallation] = useState(null);
  
  const [selectedOccupancy, setSelectedOccupancy] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedCondition, setSelectedCondition] = useState('');
  const [selectedSubcondition, setSelectedSubcondition] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOccupancies();
  }, []);

  useEffect(() => {
    if (selectedOccupancy) {
      fetchGroups();
      resetSelections();
    }
  }, [selectedOccupancy]);

  useEffect(() => {
    if (selectedOccupancy && selectedGroup) {
      fetchConditions();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedCondition) {
      fetchSubconditions();
      setSelectedSubcondition('');
      setInstallation(null);
    }
  }, [selectedCondition]);

  const resetSelections = () => {
    setSelectedGroup('');
    setSelectedCondition('');
    setSelectedSubcondition('');
    setConditions([]);
    setSubconditions([]);
    setInstallation(null);
  };

  const fetchOccupancies = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nbc/occupancy`);
      if (response.data.success) {
        setOccupancies(response.data.data);
      }
    } catch (error) {
      setError('Error fetching occupancies');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGroups = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nbc/group/${selectedOccupancy}`);
      if (response.data.success) {
        setGroups(response.data.data);

        // If there are no groups, fetch conditions directly
        if (response.data.data.length === 0) {
          fetchConditions();
        }
      }
    } catch (error) {
      setError('Error fetching groups');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchConditions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nbc/conditions`, {
        params: {
          occupancyId: selectedOccupancy,
          groupId: selectedGroup
        }
      });
      
      if (response.data.success) {
        setConditions(response.data.data);
        
        // If there are no conditions, fetch installation details directly
        if (!response.data.hasConditions) {
          fetchSubconditions();
        }
      }
    } catch (error) {
      setError('Error fetching conditions');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubconditions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nbc/subconditions/${selectedCondition}`);
      if (response.data.success) {
        setSubconditions(response.data.data);
        
        // If there are no subconditions, fetch installation details
        if (response.data.data.length === 0) {
          fetchInstallation();
        }
      }
    } catch (error) {
      setError('Error fetching subconditions');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  
  // Update the fetchInstallation function in your main component
  const fetchInstallation = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nbc/installation`, {
        params: {
          occupancyId: selectedOccupancy,
          groupId: selectedGroup || null,
          conditionId: selectedCondition || null,
          subconditionId: selectedSubcondition || null
        }
      });
      if (response.data.success && response.data.data[0]) {
        const installationId = response.data.data[0].id;
        const detailsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/nbc/installation-details/${installationId}`);
        if (detailsResponse.data.success) {
          setInstallation(detailsResponse.data.data);
          console.log('Installation:', detailsResponse.data.data); 
        }
      }
    } catch (error) {
      setError('Error fetching installation details');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle selection changes
  const handleGroupChange = (e) => {
    const value = e.target.value;
    setSelectedGroup(value);
    setSelectedCondition('');
    setSelectedSubcondition('');
    setInstallation(null);
  };

  const handleConditionChange = (e) => {
    const value = e.target.value;
    setSelectedCondition(value);
    setSelectedSubcondition('');
    setInstallation(null);
  };

  const handleSubconditionChange = (e) => {
    const value = e.target.value;
    setSelectedSubcondition(value);
    fetchInstallation();
  };

  // Add this component to display installation details
  const InstallationDetails = ({ installation }) => {
    if (!installation) return null;
  
    // Create required_installations object from the installation data
    const required_installations = {};
    const installationTypes = [
      'fire_extinguisher',
      'first_aid_hose_reel',
      'wet_riser',
      'down_comer',
      'yard_hydrant',
      'automatic_sprinkler',
      'manually_operated_fa',
      'automatic_detection'
    ];
  
    installationTypes.forEach(type => {
      if (installation[type] === 'R') {
        required_installations[type] = true;
      }
    });
  
    const formatTitle = (str) => {
      return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };
  
    return (
      <div className="mt-6 space-y-6">
        {/* Required Installations */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-4">Required Installations</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {Object.keys(required_installations).map(installation => (
              <div 
                key={installation}
                className="p-3 bg-green-50 rounded-lg border border-green-200"
              >
                <span className="text-green-700">
                  {formatTitle(installation)}
                </span>
              </div>
            ))}
          </div>
        </div>
  
        {/* Water Supply */}
        {installation.water_supply && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Water Supply</h3>
            <div className="grid md:grid-cols-2 gap-6">
              {/* Underground */}
              <div className="space-y-2">
                <h4 className="font-medium">Underground</h4>
                {installation.water_supply.underground !== 'NR' ? (
                  <>
                    <p className="text-lg">{installation.water_supply.underground} Litres</p>
                    {installation.water_supply.underground_notes?.length > 0 && (
                      <ul className="list-disc list-inside text-sm text-gray-600 pl-4">
                        {installation.water_supply.underground_notes.map((note, idx) => (
                          <li key={idx}>{note}</li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <p className="text-gray-500">Not Required</p>
                )}
              </div>
  
              {/* Terrace */}
              <div className="space-y-2">
                <h4 className="font-medium">Terrace</h4>
                {installation.water_supply.terrace !== 'NR' ? (
                  <>
                    <p className="text-lg">{installation.water_supply.terrace} Litres</p>
                    {installation.water_supply.terrace_notes?.length > 0 && (
                      <ul className="list-disc list-inside text-sm text-gray-600 pl-4">
                        {installation.water_supply.terrace_notes.map((note, idx) => (
                          <li key={idx}>{note}</li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <p className="text-gray-500">Not Required</p>
                )}
              </div>
            </div>
          </div>
        )}
  
        {/* Pump Capacity */}
        {installation.pump_capacity && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Pump Capacity</h3>
            <div className="grid md:grid-cols-2 gap-6">
              {/* Underground */}
              <div className="space-y-2">
                <h4 className="font-medium">Underground</h4>
                {installation.pump_capacity.underground !== 'NR' ? (
                  <>
                    <p className="text-lg">{installation.pump_capacity.underground} LPM</p>
                    {installation.pump_capacity.underground_notes?.length > 0 && (
                      <ul className="list-disc list-inside text-sm text-gray-600 pl-4">
                        {installation.pump_capacity.underground_notes.map((note, idx) => (
                          <li key={idx}>{note}</li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <p className="text-gray-500">Not Required</p>
                )}
              </div>
  
              {/* Terrace */}
              <div className="space-y-2">
                <h4 className="font-medium">Terrace</h4>
                {installation.pump_capacity.terrace !== 'NR' ? (
                  <>
                    <p className="text-lg">{installation.pump_capacity.terrace} LPM</p>
                    {installation.pump_capacity.terrace_notes?.length > 0 && (
                      <ul className="list-disc list-inside text-sm text-gray-600 pl-4">
                        {installation.pump_capacity.terrace_notes.map((note, idx) => (
                          <li key={idx}>{note}</li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <p className="text-gray-500">Not Required</p>
                )}
              </div>
            </div>
          </div>
        )}
  
        {/* Installation Notes */}
        {installation.notes && Object.keys(installation.notes).some(key => installation.notes[key]?.length > 0) && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Additional Notes</h3>
            <div className="space-y-4">
              {Object.entries(installation.notes).map(([key, noteList]) => (
                noteList?.length > 0 && (
                  <div key={key} className="space-y-2">
                    <h4 className="font-medium">{formatTitle(key)}</h4>
                    <ul className="list-disc list-inside text-sm text-gray-600 pl-4">
                      {noteList.map((note, idx) => (
                        <li key={idx}>{note}</li>
                      ))}
                    </ul>
                  </div>
                )
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };
  
  return (
    <div className="py-12 font-['Montserrat']">
      <div className="container mx-auto px-4 max-w-7xl">
        <h1 className="text-4xl font-bold mb-12 text-black text-center">
          NBC Table 7
        </h1>

        <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
          <div className="grid gap-6">
            {/* Occupancy Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Select Occupancy
              </label>
              <select
                value={selectedOccupancy}
                onChange={(e) => setSelectedOccupancy(e.target.value)}
                className="w-full px-4 py-3 text-gray-700 bg-gray-100 border-0 rounded-lg focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                disabled={isLoading}
              >
                <option value="">Select an occupancy</option>
                {occupancies.map((occupancy) => (
                  <option key={occupancy.id} value={occupancy.id}>
                    {occupancy.type}
                  </option>
                ))}
              </select>
            </div>

            {/* Group Selection */}
            {groups.length > 0 && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Select Group
                </label>
                <select
                  value={selectedGroup}
                  onChange={handleGroupChange}
                  className="w-full px-4 py-3 text-gray-700 bg-gray-100 border-0 rounded-lg focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                  disabled={isLoading}
                >
                  <option value="">Select a group</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Condition Selection */}
            {conditions.length > 0 && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Select Condition
                </label>
                <select
                  value={selectedCondition}
                  onChange={handleConditionChange}
                  className="w-full px-4 py-3 text-gray-700 bg-gray-100 border-0 rounded-lg focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                  disabled={isLoading}
                >
                  <option value="">Select a condition</option>
                  {conditions.map((condition) => (
                    <option key={condition.id} value={condition.id}>
                      {condition.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Subcondition Selection */}
            {subconditions.length > 0 && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Select Subcondition
                </label>
                <select
                  value={selectedSubcondition}
                  onChange={handleSubconditionChange}
                  className="w-full px-4 py-3 text-gray-700 bg-gray-100 border-0 rounded-lg focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
                  disabled={isLoading}
                >
                  <option value="">Select a subcondition</option>
                  {subconditions.map((subcondition) => (
                    <option key={subcondition.id} value={subcondition.id}>
                      {subcondition.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Loading State */}
            {isLoading && (
              <div className="flex justify-center items-center h-12">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-black"></div>
              </div>
            )}

            {/* Error State */}
            {error && (
              <div className="text-red-500 text-center py-2">
                {error}
              </div>
            )}

            {installation && (
              <InstallationDetails installation={installation} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NBCTable;