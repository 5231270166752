import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const PublicAddressSystem = ({ addresses = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Public Address System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {addresses.length > 0 &&
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                <th className="p-3 text-sm font-semibold text-left">Make</th>
                <th className="p-3 text-sm font-semibold text-left">Model</th>
                <th className="p-3 text-sm font-semibold text-left">Amplifier Capacity (in Watts)</th>
                <th className="p-3 text-sm font-semibold text-left">Amplifier Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Location</th>
                <th className="p-3 text-sm font-semibold text-left">Address</th>
                <th className="p-3 text-sm font-semibold text-left">Speaker Type</th>
                <th className="p-3 text-sm font-semibold text-left">Speaker Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Cable Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Clean</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {addresses.map((address, index) => {
                const speakers = address.Speakers || [];
                const speakersCount = Math.max(speakers.length, 1);
                return (
                  <React.Fragment key={index}>
                    <tr className="hover:bg-gray-50">
                      <td className="p-3 text-sm border" rowSpan={speakersCount}>{index + 1}</td>
                      <td className="p-3 text-sm border" rowSpan={speakersCount}>{address.Make || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakersCount}>{address.Model || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakersCount}>{address.Amp_Wattage || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakersCount}>{address.Amp_Cond || '---'}</td>
                      <td className="p-3 text-sm border">{speakers[0]?.Location || '---'}</td>
                      <td className="p-3 text-sm border">{speakers[0]?.Address || '---'}</td>
                      <td className="p-3 text-sm border">{speakers[0]?.Type || '---'}</td>
                      <td className="p-3 text-sm border">{speakers[0]?.Speaker_Cond || '---'}</td>
                      <td className="p-3 text-sm border">{speakers[0]?.Cable_Cond || '---'}</td>
                      <td className="p-3 text-sm border">{speakers[0]?.Clean || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakersCount}>{address.Remark || '---'}</td>
                    </tr>
                    {speakers.slice(1).map((speaker, speakerIndex) => (
                      <tr key={`${index}-${speakerIndex}`} className="hover:bg-gray-50">
                        <td className="p-3 text-sm border">{speaker.Location || '---'}</td>
                        <td className="p-3 text-sm border">{speaker.Address || '---'}</td>
                        <td className="p-3 text-sm border">{speaker.Type || '---'}</td>
                        <td className="p-3 text-sm border">{speaker.Speaker_Cond || '---'}</td>
                        <td className="p-3 text-sm border">{speaker.Cable_Cond || '---'}</td>
                        <td className="p-3 text-sm border">{speaker.Clean || '---'}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      }
    </div>
  );
};

export default PublicAddressSystem;