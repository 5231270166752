import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

import PrivacyPolicy from './components/PrivacyPolicy';
import Profile from './components/Profile';
import Layout from './components/Layout';
import Login from './components/Login';
import SurveyReports from './components/SurveyReports';
import BasicSurvey from './components/SurveyForms/BasicSurvey';
import DetailedSurvey from './components/SurveyForms/detailedSurvey';
import FormB from './components/SurveyForms/formB';
import AMCSurvey from './components/AMCs/Survey/AMCSurvey';
import Dashboard from './components/Dashboard';
import AMC from './components/AMCs/AMC';
import AMCInitialization from './components/AMCs/AMCInitialization';
import DocumentsManager from './components/Documents';
import FillDocuments from './components/FillDocuments';

import NBC from './components/NBC';
import Projects from './components/Projects/Projects';
import ProjectInitialization from './components/Projects/ProjectInitialization';
import ProjectDetails from './components/Projects/ProjectDetails';
import BOQ from './components/Projects/BOQ';
import BOQDisplay from './components/Projects/BOQDisplay';
import MaterialRequisition from './components/Projects/MaterialRequisition';
import MaterialRequisitionSheet from './components/Projects/MaterialRequisitionSheet';
import ProjectTrackingSheet from './components/Projects/ProjectTrackingSheet';

import { NotificationProvider } from './components/NotificationProvider';

// Protected Route Component
function ProtectedRoute({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = () => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    // Redirect to login with the attempted location
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
}

// Public Route Component - redirects to dashboard if already logged in
function PublicRoute({ children }) {
  const token = localStorage.getItem('token');
  const location = useLocation();
  
  // If user is already logged in and tries to access login page,
  // redirect them to dashboard or their attempted location
  if (token) {
    const destination = location.state?.from || '/dashboard';
    return <Navigate to={destination} replace />;
  }

  return children;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  return (
    <div className="font-['Montserrat']">
      <NotificationProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route 
              path="/login" 
              element={
                <PublicRoute>
                  <Login setIsLoggedIn={setIsLoggedIn} />
                </PublicRoute>
              } 
            />
            
            {/* Protected routes */}
            <Route
              element={
                <ProtectedRoute>
                  <Layout setIsLoggedIn={setIsLoggedIn} />
                </ProtectedRoute>
              }
            >
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              
              <Route path="/baseSurveys" element={<SurveyReports />} />
              <Route path="/profile" element={<Profile />} />

              <Route path="/baseSurveys/basicSurvey" element={<BasicSurvey />} />
              <Route path="/baseSurveys/detailedSurvey" element={<DetailedSurvey />} />
              <Route path="/baseSurveys/formB" element={<FormB />} />

              <Route path="/amc" element={<AMC />} />
              <Route path="/amc/amc-contract" element={<AMCInitialization />} />
              <Route path="/amc/AMCSurvey" element={<AMCSurvey />} />

              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/documents" element={<DocumentsManager />} />
              <Route path="/documents/fill-documents" element={<FillDocuments />} />
              
              <Route path="/nbc" element={<NBC />} />
              {/* <Route path="/project" element={<Projects />} />
              <Route path="/project/project-initialization" element={<ProjectInitialization />} />
              <Route path="/project/:projectId" element={<ProjectDetails />} />
              <Route path="/boq" element={<BOQ />} />
              <Route path="/boq/:projectId" element={<BOQDisplay />} />
              <Route path="/materialRequisition/:projectId" element={<MaterialRequisition />} />
              <Route path="/materialRequisitionSheet/:projectId" element={<MaterialRequisitionSheet />} />
              <Route path="/projectTrackingSheet/:projectId" element={<ProjectTrackingSheet />} /> */}
            </Route>

            {/* Catch all route - redirect to dashboard if logged in, otherwise to login */}
            <Route 
              path="*" 
              element={
                isLoggedIn ? 
                  <Navigate to="/dashboard" replace /> : 
                  <Navigate to="/login" replace />
              } 
            />
          </Routes>
        </Router>
      </NotificationProvider>
    </div>
    
  );
}

export default App;

