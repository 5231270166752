import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const WaterLeakDetectorSystem = ({ devices = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Water Leak Detector System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {devices.length > 0 &&
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                  <th className="p-3 text-sm font-semibold text-left">Panel Make</th>
                  <th className="p-3 text-sm font-semibold text-left">Quantity of Hooter</th>
                  <th className="p-3 text-sm font-semibold text-left">Working Condition</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {devices.map((device, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{device.Make || '---'}</td>
                    <td className="p-3 text-sm border">{device.Hooter || '---'}</td>
                    <td className="p-3 text-sm border">{device.Working_Cond === 'No' ? device.Remark : device.Working_Cond || '---'}</td>
                    <td className="p-3 text-sm border">{device.Write_Remarks || '---'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  );
};

export default WaterLeakDetectorSystem;