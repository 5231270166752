import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, Shield, Lock, Users, Database, Share2, UserCheck, Clock, Baby, FileText, Phone } from 'lucide-react';

export default function PrivacyPolicy() {
  const [activeSection, setActiveSection] = useState('');
  const [isNavSticky, setIsNavSticky] = useState(false);

  const sections = [
    { id: 'introduction', title: '1. Introduction', icon: Shield },
    { id: 'information-we-collect', title: '2. Information We Collect', icon: Database },
    { id: 'how-we-use-your-information', title: '3. How We Use Your Information', icon: Users },
    { id: 'data-storage-and-security', title: '4. Data Storage and Security', icon: Lock },
    { id: 'data-sharing-and-disclosure', title: '5. Data Sharing and Disclosure', icon: Share2 },
    { id: 'your-rights', title: '6. Your Rights', icon: UserCheck },
    { id: 'data-retention', title: '7. Data Retention', icon: Clock },
    { id: 'childrens-privacy', title: "8. Children's Privacy", icon: Baby },
    { id: 'changes-to-this-privacy-policy', title: '9. Changes to This Privacy Policy', icon: FileText },
    { id: 'contact-us', title: '10. Contact Us', icon: Phone },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sectionElements = document.querySelectorAll('h2');
      
      setIsNavSticky(scrollPosition > 100);
      
      for (let i = sectionElements.length - 1; i >= 0; i--) {
        const section = sectionElements[i];
        if (section.offsetTop <= scrollPosition + 100) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 80; // Height of sticky header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gray-50 font-['Montserrat']"
    >
      <div className="bg-white border-b">
        <div className="container mx-auto px-4 py-8">
          <motion.h1 
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            className="text-4xl font-bold text-center"
          >
            Privacy Policy
          </motion.h1>
          <motion.p 
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-center text-gray-600 mt-2"
          >
            for Safety सारथी
          </motion.p>
          <motion.p 
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-sm text-gray-500 text-center mt-2"
          >
            Last updated: {new Date().toLocaleDateString()}
          </motion.p>
        </div>
      </div>
      
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        <div className="flex flex-col lg:flex-row gap-8">
          <nav className={`lg:w-1/4 ${isNavSticky ? 'lg:sticky' : ''} top-4 h-fit`}>
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4 pb-2 border-b">Table of Contents</h2>
              <ul className="space-y-2">
                {sections.map((section) => {
                  const Icon = section.icon;
                  return (
                    <motion.li 
                      key={section.id}
                      whileHover={{ x: 4 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <button
                        onClick={() => scrollToSection(section.id)}
                        className={`text-left w-full py-2 px-3 rounded-lg flex items-center gap-3 transition-all duration-200 ${
                          activeSection === section.id 
                            ? 'bg-black text-white' 
                            : 'hover:bg-gray-100 text-gray-700'
                        }`}
                      >
                        <Icon size={18} className="flex-shrink-0" />
                        <span className="text-sm">{section.title}</span>
                      </button>
                    </motion.li>
                  );
                })}
              </ul>
            </div>
          </nav>
          
          <div className="lg:w-3/4">
            <div className="bg-white rounded-xl shadow-sm p-8 space-y-12">
              {sections.map((section) => {
                const Icon = section.icon;
                return (
                  <motion.section
                    key={section.id}
                    id={section.id}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    className="scroll-mt-24"
                  >
                    <div className="flex items-center gap-3 mb-6">
                      <div className="p-2 bg-gray-100 rounded-lg">
                        <Icon size={24} className="text-gray-700" />
                      </div>
                      <h2 className="text-2xl font-bold">{section.title.split('. ')[1]}</h2>
                    </div>
                    
                    {section.id === 'introduction' && (
                      <div className="space-y-4">
                        <p className="text-gray-600 leading-relaxed">
                          Safety सारथी is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platforms for fire protection system management.
                        </p>
                        <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 mt-4">
                          <p className="text-gray-800 font-medium">
                            Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the App.
                          </p>
                        </div>
                      </div>
                    )}

                    {section.id === 'information-we-collect' && (
                      <div className="space-y-6">
                        <p className="text-gray-600">We collect information that you provide directly to us when using the App, including:</p>
                        
                        {[
                          {
                            title: "Personal Information",
                            items: ["Name", "Email address", "Phone number", "Company/organization affiliation", "Job title"]
                          },
                          {
                            title: "Survey and Audit Data",
                            items: ["Location data of fire protection equipment", "Equipment details (type, quantity, installation date, maintenance dates)", "Site-specific information"]
                          },
                          {
                            title: "Employee Data (for HRMS module)",
                            items: ["Attendance records", "Performance reviews", "Payroll information"]
                          },
                          {
                            title: "Customer Data (for CRM module)",
                            items: ["Customer contact Information", "Customer GSTIN Information", "Building occupancy details", "Project specifications"]
                          }
                        ].map((section, index) => (
                          <div key={index} className="bg-gray-50 rounded-lg p-6">
                            <h3 className="text-lg font-semibold mb-3">{section.title}</h3>
                            <ul className="space-y-2">
                              {section.items.map((item, i) => (
                                <li key={i} className="flex items-center gap-2 text-gray-600">
                                  <ChevronRight size={16} className="text-gray-400" />
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    )}

                    {section.id === 'how-we-use-your-information' && (
                      <div className="space-y-4">
                        <p className="text-gray-600">We use the information we collect for various purposes, including:</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                          {[
                            "Providing and maintaining the App's functionality",
                            "Generating reports and proposals",
                            "Managing customer relationships",
                            "Coordinating operations and inventory",
                            "Human resource management",
                            "Improving and optimizing the App"
                          ].map((item, i) => (
                            <div key={i} className="bg-gray-50 p-4 rounded-lg flex items-center gap-3">
                              <ChevronRight size={16} className="text-gray-400 flex-shrink-0" />
                              <span className="text-gray-600">{item}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {section.id === 'data-storage-and-security' && (
                      <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
                        <p className="text-gray-600 leading-relaxed">
                          We implement appropriate technical and organizational security measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                        </p>
                      </div>
                    )}

                    {section.id === 'data-sharing-and-disclosure' && (
                      <div className="space-y-4">
                        <p className="text-gray-600">We may share your information in the following situations:</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {[
                            "With your consent",
                            "To comply with legal obligations",
                            "To protect our rights, privacy, safety, or property",
                            "In connection with a business transfer or merger"
                          ].map((item, i) => (
                            <div key={i} className="bg-gray-50 p-4 rounded-lg flex items-center gap-3">
                              <ChevronRight size={16} className="text-gray-400 flex-shrink-0" />
                              <span className="text-gray-600">{item}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {section.id === 'your-rights' && (
                      <div className="space-y-4">
                        <p className="text-gray-600">Depending on your location, you may have certain rights regarding your personal information, including:</p>
                        <div className="space-y-3">
                          {[
                            "Access to your personal information",
                            "Objection to or restriction of processing"
                          ].map((item, i) => (
                            <div key={i} className="bg-gray-50 p-4 rounded-lg flex items-center gap-3">
                              <ChevronRight size={16} className="text-gray-400 flex-shrink-0" />
                              <span className="text-gray-600">{item}</span>
                            </div>
                          ))}
                        </div>
                        <p className="text-gray-600 mt-4">
                          To exercise these rights, please contact us using the information provided in the "Contact Us" section.
                        </p>
                      </div>
                    )}

                    {section.id === 'data-retention' && (
                      <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
                        <p className="text-gray-600 leading-relaxed">
                          We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required by law.
                        </p>
                      </div>
                    )}

                    {section.id === 'childrens-privacy' && (
                      <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
                        <p className="text-gray-600 leading-relaxed">
                          The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
                        </p>
                      </div>
                    )}

                    {section.id === 'changes-to-this-privacy-policy' && (
                      <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
                        <p className="text-gray-600 leading-relaxed">
                          We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last updated" date.
                        </p>
                      </div>
                    )}

                    {section.id === 'contact-us' && (
                      <div className="space-y-4">
                        <p className="text-gray-600">If you have any questions about this privacy policy or our practices, please contact us at:</p>
                        <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
                          <h3 className="font-semibold mb-3">NFPA (National Fire Protection Armour) India Pvt. Ltd.</h3>
                          <div className="space-y-2 text-gray-600">
                            <p><span className="font-medium">Address:</span> Gat No. 447, Plot: E-20-30, Maitri Park, Near Ganesh Samrajya Chowk, Moshi, Pune - 412105</p>
                            <p><span className="font-medium">Email:</span> info@safetysaarthi.com</p>
                            <p><span className="font-medium">Phone:</span> 7066729911 / 9673407788</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </motion.section>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
