import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { motion } from 'framer-motion'
import NavbarSidebar from './NavbarSidebar'

export default function Layout({ setIsLoggedIn }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768)
    }

    handleResize() // Initial check
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className="flex h-screen">
      <NavbarSidebar
        setIsLoggedIn={setIsLoggedIn}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <motion.div
        initial={false}
        animate={{
          marginLeft: !isSmallScreen ? (isSidebarOpen ? 256 : 80) : 0,
          transition: { duration: 0.3, ease: "easeInOut" }
        }}
        className="flex-1 flex flex-col overflow-hidden"
      >
        <main className="flex-1 overflow-x-hidden overflow-y-auto pt-16">
          <motion.div 
            initial={false}
            animate={{
              padding: "2rem 1.5rem",
              maxWidth: !isSmallScreen ? (isSidebarOpen ? "1280px" : "1536px") : "100%",
              transition: { duration: 0.3, ease: "easeInOut" }
            }}
            className="mx-auto"
          >
            <Outlet />
          </motion.div>
        </main>
      </motion.div>
    </div>
  )
}