import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const RoomSuppressionSystem = ({ rooms = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = rooms.some(room => room.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Room Suppression System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {rooms.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr  className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                  <th className="p-3 text-sm font-semibold text-left">Application Area</th>
                  <th className="p-3 text-sm font-semibold text-left">Type of System</th>
                  <th className="p-3 text-sm font-semibold text-left">Make of Cylinder</th>
                  <th className="p-3 text-sm font-semibold text-left">Capacity of Cylinder</th>
                  <th className="p-3 text-sm font-semibold text-left">Last Refilled Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Last HPT Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Make of Gas Release Panel</th>
                  <th className="p-3 text-sm font-semibold text-left">Net weight</th>
                  <th className="p-3 text-sm font-semibold text-left">Empty Body Weight</th>
                  <th className="p-3 text-sm font-semibold text-left">SD Qty</th>
                  <th className="p-3 text-sm font-semibold text-left">HD Qty</th>
                  <th className="p-3 text-sm font-semibold text-left">MD Qty</th>
                  <th className="p-3 text-sm font-semibold text-left">Abort MCP</th>
                  <th className="p-3 text-sm font-semibold text-left">Release MCP</th>
                  <th className="p-3 text-sm font-semibold text-left">Hooter</th>
                  <th className="p-3 text-sm font-semibold text-left">Overall Working Condition of System</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {rooms.map((room, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{room.App_Area || '---'}</td>
                    <td className="p-3 text-sm border">{room.Type || room.Other_Type || '---'}</td>
                    <td className="p-3 text-sm border">{room.Make_Of_Cyl || '---'}</td>
                    <td className="p-3 text-sm border">{room.Cap_Of_Cyl || '---'}</td>
                    <td className="p-3 text-sm border">{room.Last_Refilled_Date || '---'}</td>
                    <td className="p-3 text-sm border">{room.Last_Hpt_Date || '---'}</td>
                    <td className="p-3 text-sm border">{room.Make_Of_Panel || '---'}</td>
                    <td className="p-3 text-sm border">{room.Net_Wt || '---'}</td>
                    <td className="p-3 text-sm border">{room.Empty_Body_Wt || '---'}</td>
                    <td className="p-3 text-sm border">{room.SD_Qty || '---'}</td>
                    <td className="p-3 text-sm border">{room.HD_Qty || '---'}</td>
                    <td className="p-3 text-sm border">{room.MD_Qty || '---'}</td>
                    <td className="p-3 text-sm border">{room.Abort_MCP || '---'}</td>
                    <td className="p-3 text-sm border">{room.Release_MCP || '---'}</td>
                    <td className="p-3 text-sm border">{room.Hooter || '---'}</td>
                    <td className="p-3 text-sm border">{room.Working_Cond === 'No' ? room.Remark : room.Working_Cond}</td>
                    <td className="p-3 text-sm border">{room.Write_Remarks || '---'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }

      {newSystemExists && rooms.length > 0 && (
        <>
          <CollapsibleHeading 
            title="New Recommendations for Room Suppression System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">Application Area</th>
                    <th className="p-3 text-sm font-semibold text-left">Type of System</th>
                    <th className="p-3 text-sm font-semibold text-left">Room Length</th>
                    <th className="p-3 text-sm font-semibold text-left">Room Width</th>
                    <th className="p-3 text-sm font-semibold text-left">Room Height</th>
                    <th className="p-3 text-sm font-semibold text-left">False Ceiling Height</th>
                    <th className="p-3 text-sm font-semibold text-left">False Floor Height</th>
                    <th className="p-3 text-sm font-semibold text-left">No of RSS Systems</th>
                    <th className="p-3 text-sm font-semibold text-left">Write Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {rooms
                    .filter(room => room.New_System_Rec === 'Yes')
                    .flatMap((room, roomIndex) => {
                      const newSystems = Array.isArray(room.New_Systems) ? room.New_Systems : [room.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${roomIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{roomIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.App_Area || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Type || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Room_Length || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Room_Width || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Room_Height || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.False_Ciel_Height || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.False_Floor_Height || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.No_Of_Rss || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RoomSuppressionSystem;