import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { Eye, Calendar, User, MapPin, PlusCircle, Search, FileX, Pen, ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import AMCDetailsDialog from './AMCDetailsDialog';

function TableHeader({ sortConfig, onSort }) {

  return (
    <thead className="bg-black text-white">
      <tr>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            AMC Name
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            AMC ID
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            <User size={16} />
            Client
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            <MapPin size={16} />
            Location
          </div>
        </th>
        <th className="px-6 py-4 text-left font-['Montserrat'] text-sm font-semibold">
          <div className="flex items-center gap-2">
            <Calendar size={16} />
            Start Date
          </div>
        </th>
        <th className="px-6 py-4 text-right font-['Montserrat'] text-sm font-semibold">Actions</th>
      </tr>
    </thead>
  );
}

export default function AMCManagement() {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [activeTab, setActiveTab] = useState('Ongoing');
  const [amcs, setAMCs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isManager, setIsManager] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAMCs, setTotalAMCs] = useState(0);
  const [selectedAMC, setSelectedAMC] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAMCs();
    checkUserRole();
  }, [activeTab, currentPage, searchQuery]);

  const fetchAMCs = async () => {
    setLoading(true);
    setError(null);

    const params = {
      status: activeTab,
      searchQuery: searchQuery || undefined,
      amcsPerPage: 10,
      pageNumber: currentPage
    };

    try {
      const response = await axios.get(`${API_URL}/api/amcs`, { params });
      setAMCs(response.data.amcs);
      setTotalPages(response.data.totalPages);
      setTotalAMCs(response.data.totalAMCs);
    } catch (err) {
      if (err.response?.status === 404) {
        setAMCs([]);
        setTotalPages(0);
        setTotalAMCs(0);
      } else {
        setError('Failed to fetch AMCs. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const checkUserRole = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsManager(decodedToken.role === 'Manager');
      } catch (error) {
        console.error('Error decoding token:', error);
        setIsManager(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    setSearchQuery('');
  };

  const handleRowClick = (amc) => {
    setSelectedAMC(amc);
    setIsDialogOpen(true);
  };

  const handleEditAMC = (amc) => {
    navigate('/amc/amc-contract', { state: { amc_id: amc.amc_id } });
  };

  return (
    <div className="py-12 font-['Montserrat']">
      <div className="container mx-auto px-4 max-w-7xl">
        <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-12 text-black text-center"
        >
          AMC Management
        </motion.h1>

        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-xl shadow-lg p-6 mb-8"
        >
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <div className="flex space-x-4">
              {['Ongoing', 'Completed'].map((tab) => (
                <motion.button
                  key={tab}
                  onClick={() => handleTabChange(tab)}
                  className={`
                    px-6 py-3 rounded-full font-medium text-sm transition-all duration-300
                    ${activeTab === tab
                      ? 'bg-black text-white shadow-lg'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    }
                  `}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {tab} AMCs
                </motion.button>
              ))}
            </div>

            <div className="flex-grow relative">
              <input
                type="text"
                placeholder="Search AMCs by name or client"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full px-4 py-3 pl-10 text-gray-700 bg-gray-100 border-0 rounded-full focus:bg-white focus:ring-2 focus:ring-black transition-all duration-300"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>

            {isManager && (
              <motion.button
                onClick={() => navigate('/amc/amc-contract')}
                className="bg-black text-white px-6 py-3 rounded-full font-medium text-sm hover:bg-gray-800 flex items-center shadow-lg whitespace-nowrap"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <PlusCircle size={18} className="mr-2" />
                Initialize AMC
              </motion.button>
            )}
          </div>
        </motion.div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
              </div>
            ) : error ? (
              <div className="text-red-500 text-center p-6 bg-red-50 rounded-xl border border-red-100">
                {error}
              </div>
            ) : amcs.length > 0 ? (
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="">
                  <table className="w-full">
                    <TableHeader />
                    <tbody>
                      {amcs.map((amc) => (
                        <tr
                          key={amc.amc_id}
                          className="text-sm border-b border-gray-200 hover:bg-gray-50 transition-colors cursor-pointer"
                          onClick={() => handleRowClick(amc)}
                        >
                          <td className="px-6 py-4">
                            <div className="font-medium">{amc.AMC_Name}</div>
                          </td>
                          <td className="px-6 py-4">{amc.amc_id}</td>
                          <td className="px-6 py-4">{amc.Buyer.Name}</td>
                          <td className="px-6 py-4 truncate max-w-[200px]">
                            {amc.Buyer.Address}
                          </td>
                          <td className="px-6 py-4">{amc.date}</td>
                          <td className="px-6 py-4">
                            <div className="flex items-center justify-end gap-2" onClick={e => e.stopPropagation()}>
                              {amc.Uploaded_File && (
                                <button
                                  onClick={() => window.open(amc.Uploaded_File, '_blank')}
                                  className="p-2 text-black hover:bg-gray-100 rounded-full"
                                  title="View Contract"
                                >
                                  <Eye size={18} />
                                </button>
                              )}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditAMC(amc);
                                }}
                                className="p-2 text-black hover:bg-gray-100 rounded-full"
                                title="Edit AMC"
                              >
                                <Pen size={18} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {totalPages > 1 && (
                  <div className="flex items-center justify-between px-6 py-4 bg-white border-t border-gray-200">
                    <div className="flex items-center gap-2">
                      <span className="text-sm">
                        Page {currentPage} of {totalPages}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="p-2 text-black hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronLeft size={18} />
                      </button>
                      <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="p-2 text-black hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronRight size={18} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center justify-center h-64 bg-white rounded-xl shadow-lg p-8"
              >
                <FileX size={48} className="text-gray-400 mb-4" />
                <h2 className="text-2xl font-semibold text-gray-700 mb-2">No AMCs Found</h2>
                <p className="text-gray-500 text-center max-w-md">
                  There are no AMCs matching your current filters. Try adjusting your search criteria or initialize a new AMC.
                </p>
                {isManager && (
                  <motion.button
                    onClick={() => navigate('/amc/amc-contract')}
                    className="mt-6 bg-black text-white px-6 py-3 rounded-full font-medium text-sm hover:bg-gray-800 flex items-center shadow-lg"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <PlusCircle size={18} className="mr-2" />
                    Initialize New AMC
                  </motion.button>
                )}
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
      
      <AMCDetailsDialog
        amcId={selectedAMC?.amc_id}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onEdit={() => selectedAMC && handleEditAMC(selectedAMC)}
      />
    </div>
  );
}