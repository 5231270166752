import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const FireAlarmDetectionSystem = ({ alarms = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Alarm Detection System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {alarms.length > 0 &&
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="overflow-x-auto border rounded-lg shadow-sm">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 text-sm font-semibold text-left">Sr No</th>
                  <th className="p-3 text-sm font-semibold text-left">Make</th>
                  <th className="p-3 text-sm font-semibold text-left">Model</th>
                  <th className="p-3 text-sm font-semibold text-left">Panel No.</th>
                  <th className="p-3 text-sm font-semibold text-left">Panel Condition</th>
                  <th className="p-3 text-sm font-semibold text-left">Location</th>
                  <th className="p-3 text-sm font-semibold text-left">Type</th>
                  <th className="p-3 text-sm font-semibold text-left">No. of Loops / Zones</th>
                  <th className="p-3 text-sm font-semibold text-left">Device</th>
                  <th className="p-3 text-sm font-semibold text-left">Quantity</th>
                  <th className="p-3 text-sm font-semibold text-left">Address</th>
                  <th className="p-3 text-sm font-semibold text-left">Blink</th>
                  <th className="p-3 text-sm font-semibold text-left">Cable Condition</th>
                  <th className="p-3 text-sm font-semibold text-left">Clean</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {alarms.map((alarm, index) => {
                  const deviceTypes = alarm.Type === 'Conventional' 
                    ? (alarm.Conventional?.Device_Types || [])
                    : (alarm.Addressable?.Device_Types || []);
                  const devicesCount = Math.max(deviceTypes.length, 1);
                  return (
                    <React.Fragment key={index}>
                      <tr className="hover:bg-gray-50 transition-colors duration-150">
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Sr_No || index + 1}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Alarm_Make || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Alarm_Model_No || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Panel_No || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Panel_Cond || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Location || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Type || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>
                          {alarm.Type === 'Conventional' 
                            ? (alarm.Conventional?.Alarm_No_Of_Zones || '---')
                            : (alarm.Addressable?.Alarm_No_Of_Loops || '---')}
                        </td>
                        <td className="p-3 text-sm border">{deviceTypes[0]?.Device_Name || '---'}</td>
                        <td className="p-3 text-sm border">{deviceTypes[0]?.Quantity || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Address || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Blink || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Cable_Cond || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Clean || '---'}</td>
                        <td className="p-3 text-sm border" rowSpan={devicesCount}>{alarm.Remarks || '---'}</td>
                      </tr>
                      {deviceTypes.slice(1).map((device, deviceIndex) => (
                        <tr key={`${index}-${deviceIndex}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{device.Device_Name || '---'}</td>
                          <td className="p-3 text-sm border">{device.Quantity || '---'}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  );
};

export default FireAlarmDetectionSystem;