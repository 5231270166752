import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const TubeSuppressionSystem = ({ tubes = [], files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Tube Suppression System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {tubes.length > 0 &&
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                <th className="p-3 text-sm font-semibold text-left">Application area</th>
                <th className="p-3 text-sm font-semibold text-left">Refilling required</th>
                <th className="p-3 text-sm font-semibold text-left">Cylinder Capacity</th>
                <th className="p-3 text-sm font-semibold text-left">System Make</th>
                <th className="p-3 text-sm font-semibold text-left">System Type</th>
                <th className="p-3 text-sm font-semibold text-left">Type of gas</th>
                <th className="p-3 text-sm font-semibold text-left">Overall Working Condition Of System</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {tubes.map((tube, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{tube?.App_Area !== 'N/A' ? tube?.App_Area : tube.Other_Area || '---'}</td>
                  <td className="p-3 text-sm border">
                    {tube?.Refill_Req === 'Yes' 
                      ? `Last Refilled: ${tube.Last_Refilled_Date}\nNext Refill Date: ${tube.Next_Refill_Date}`
                      : tube.Refill_Req || '---'}
                  </td>
                  <td className="p-3 text-sm border">{tube?.Cyl_Cap ? `${tube.Cyl_Cap} Kg` : '---'}</td>
                  <td className="p-3 text-sm border">{tube?.Make || '---'}</td>
                  <td className="p-3 text-sm border">{tube?.System_Type || '---'}</td>
                  <td className="p-3 text-sm border">{tube?.Gas_Type || '---'}</td>
                  <td className="p-3 text-sm border">{tube?.Working_Cond === 'No' ? tube.Remark : tube?.Working_Cond || '---'}</td>
                  <td className="p-3 text-sm border">{tube?.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      }
    </div>
  );
};

export default TubeSuppressionSystem;