import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FileText, Download, CheckCircle, Calendar, User, ExternalLink } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function DownloadButton({ reportId, clientName }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadComplete, setIsDownloadComplete] = useState(false);

  const downloadPDF = async () => {
    setIsDownloading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/generate/amc_survey/${reportId}`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${reportId}_${clientName}.pdf`;

      document.body.appendChild(link);
      link.click();
      link.remove();

      setIsDownloadComplete(true);
      setTimeout(() => setIsDownloadComplete(false), 3000);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <motion.button 
      className={`inline-flex items-center gap-2 px-4 py-2 rounded-md transition-colors duration-300 focus:outline-none ${
        isDownloading 
          ? 'bg-gray-100 text-gray-400' 
          : isDownloadComplete 
          ? 'bg-green-50 text-green-600' 
          : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
      }`}
      onClick={downloadPDF}
      disabled={isDownloading}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      {isDownloading ? (
        <>
          <div className="animate-spin rounded-full h-4 w-4 border-2 border-gray-400 border-t-transparent" />
          <span>Downloading...</span>
        </>
      ) : isDownloadComplete ? (
        <>
          <CheckCircle size={16} />
          <span>Downloaded</span>
        </>
      ) : (
        <>
          <Download size={16} />
          <span>Download PDF</span>
        </>
      )}
    </motion.button>
  );
}

export function AMCReportsList({ amcReports = {}, clientName, amcId }) {
  const navigate = useNavigate();

  if (!amcReports || Object.keys(amcReports).length === 0) {
    return null;
  }

  const handleReportClick = (reportId) => {
    navigate('/amc/AMCSurvey', { state: { amcSurveyId: reportId, amcId } });
  };

  // Filter out 'Index' and 'Current' entries
  const filteredReports = Object.entries(amcReports).filter(
    ([key]) => !['Index', 'Current'].includes(key)
  );

  if (filteredReports.length === 0) {
    return null;
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const [day, month, year] = dateString.split('-');
    return `${day} ${new Date(year, month - 1).toLocaleString('default', { month: 'short' })} ${year}`;
  };

  return (
    <section className="mt-8">
      <h3 className="text-xl font-bold mb-4 font-['Montserrat'] border-b border-gray-200 pb-2">
        AMC Survey Reports
      </h3>
      <div className="grid grid-cols-1 gap-4">
        {filteredReports.map(([index, { reportId, engineerName, date }]) => (
          <div
            key={index}
            className="bg-white border border-gray-200 rounded-lg overflow-hidden"
          >
            <div className="grid md:grid-cols-[1fr,auto] gap-4 items-center">
              <motion.div
                className="p-4 cursor-pointer hover:bg-gray-50 transition-colors flex items-start gap-4"
                onClick={() => handleReportClick(reportId)}
                whileHover={{ x: 4 }}
              >
                <div className="flex-shrink-0 p-2 bg-gray-50 rounded-lg">
                  <FileText size={24} className="text-gray-600" />
                </div>
                <div className="min-w-0">
                  <div className="flex items-center gap-2">
                    <h4 className="font-['Montserrat'] font-medium text-sm truncate">
                      AMC Survey Report #{index}
                    </h4>
                    <ExternalLink size={14} className="text-gray-400" />
                  </div>
                  <p className="text-xs text-gray-500 font-mono mt-1 truncate">
                    {reportId}
                  </p>
                  <div className="flex flex-wrap items-center gap-4 mt-2 text-sm text-gray-600">
                    <div className="flex items-center gap-1.5 min-w-0">
                      <User size={14} className="flex-shrink-0" />
                      <span className="truncate">{engineerName || 'Not assigned'}</span>
                    </div>
                    <div className="flex items-center gap-1.5">
                      <Calendar size={14} className="flex-shrink-0" />
                      <span>{formatDate(date)}</span>
                    </div>
                  </div>
                </div>
              </motion.div>
              <div className="px-4 py-3 md:py-0 md:border-l border-gray-100 bg-gray-50 md:bg-transparent">
                <DownloadButton 
                  reportId={reportId}
                  clientName={clientName}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}