import React, { useState } from 'react';
import CollapsibleHeading from '../../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../../../SurveyForms/RenderFiles';

const FirePumpRoom = ({ firePumpRoomData = {}, files = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Pump Room System" 
        isExpanded={isExpanded} 
        onToggle={toggleExpanded}
      />
      {files.length > 0 && <RenderFiles files={files} />}
      {firePumpRoomData.length > 0 && 
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        {/* Tank Capacity Table */}
        <div className="overflow-x-auto border rounded-lg shadow-sm mb-4">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">UNDERGROUND/ MODULAR WATER TANK CAPACITY</th>
                <th className="p-3 text-sm font-semibold text-left">UNDERGROUND/ MODULAR WATER TANK CURRENT CAPACITY</th>
              </tr>
            </thead>
            <tbody>
              <tr className="hover:bg-gray-50">
                <td className="p-3 text-sm border">{firePumpRoomData.common_details?.Under_Tank_Cap || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.common_details?.Under_Tank_Current_Cap || '---'}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Pumps Table */}
        <div className="overflow-x-auto border rounded-lg shadow-sm mb-4">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">PUMP TYPE</th>
                <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                <th className="p-3 text-sm font-semibold text-left">Cut-In Pr.</th>
                <th className="p-3 text-sm font-semibold text-left">Cut-Out Pr.</th>
                <th className="p-3 text-sm font-semibold text-left">Starting Current</th>
                <th className="p-3 text-sm font-semibold text-left">Running Current</th>
                <th className="p-3 text-sm font-semibold text-left">Switch (Auto/Manual)</th>
                <th className="p-3 text-sm font-semibold text-left">Gland</th>
                <th className="p-3 text-sm font-semibold text-left">Calib. Pr. Switch</th>
                <th className="p-3 text-sm font-semibold text-left">REMARK</th>
              </tr>
            </thead>
            <tbody>
              {(firePumpRoomData.pumps || []).map((pump, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="p-3 text-sm border">{pump.Pump_Type || '---'}</td>
                  <td className="p-3 text-sm border">{`${pump.Capacity || ''}${pump.Capacity_Unit || ''}` || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Cut_In_Pr || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Cut_Out_Pr || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Starting_Current || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Running_Current || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Switch_Type || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Gland || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Callb_Pr_Switch || '---'}</td>
                  <td className="p-3 text-sm border">{pump.Remark || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Diesel Engine Table */}
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">PUMP TYPE</th>
                <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                <th className="p-3 text-sm font-semibold text-left">Check Diesel Tank Level</th>
                <th className="p-3 text-sm font-semibold text-left">Check Battery Voltage</th>
                <th className="p-3 text-sm font-semibold text-left">Electrolyte Level in Battery</th>
                <th className="p-3 text-sm font-semibold text-left">SMPS Battery Charger Voltage</th>
                <th className="p-3 text-sm font-semibold text-left">Battery Installation Date</th>
                <th className="p-3 text-sm font-semibold text-left">Battery Terminals Connection</th>
                <th className="p-3 text-sm font-semibold text-left">Total Running Hours</th>
                <th className="p-3 text-sm font-semibold text-left">Last B Check Date</th>
                <th className="p-3 text-sm font-semibold text-left">Engine Start Time</th>
                <th className="p-3 text-sm font-semibold text-left">Engine Stop Time</th>
                <th className="p-3 text-sm font-semibold text-left">Check Engine Oil Pr. (Kg/Cm2)</th>
                <th className="p-3 text-sm font-semibold text-left">Check Water Temp (°C)</th>
                <th className="p-3 text-sm font-semibold text-left">Check Coolant Level in Radiator</th>
                <th className="p-3 text-sm font-semibold text-left">REMARK</th>
              </tr>
            </thead>
            <tbody>
              <tr className="hover:bg-gray-50">
                <td className="p-3 text-sm border">Diesel Engine</td>
                <td className="p-3 text-sm border">{`${firePumpRoomData.diesel_engine?.Capacity || ''}${firePumpRoomData.diesel_engine?.Capacity_Unit || ''}` || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Diesel_Tank_Level || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Battery_Voltage || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Electrolite_Level || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.SMPS_Battery_Voltage || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Battery_In_Date || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Battery_Terminals || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Total_Running_Hours || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Last_B_Check_Date || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Engine_Start_Time || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Engine_Stop_Time || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Engine_Oil_PR || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Water_Temp || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Coolant_Level || '---'}</td>
                <td className="p-3 text-sm border">{firePumpRoomData.diesel_engine?.Remark || '---'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    }
    </div>
  );
};

export default FirePumpRoom;