import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { FileText } from 'lucide-react';

import FormA from './Documents/FormA';
import FormB from './Documents/FormB';
import PressureTestingReport from './Documents/PressureTestingReport';
import Challan from './Documents/Challan';
import ExtinguisherRefillingCertificate from './Documents/ExtinguisherRefillingCertificate';
import ExtinguisherHPTCertificate from './Documents/ExtinguisherHPTCertificate';
import AttendanceForm from './Documents/AttendanceForm';
import LeaveApplication from './Documents/LeaveApplication';
import MissPunchForm from './Documents/MissPunchForm';
import WorkCompletion from './Documents/WorkCompletion';

const TestingAndCommissioningReport = () => <div className="p-4 bg-white rounded shadow">Testing and Commissioning Report Content</div>;
const LoanApplication = () => <div className="p-4 bg-white rounded shadow">Loan Application Content</div>;
const SalaryAdvanceApplication = () => <div className="p-4 bg-white rounded shadow">Salary Advance Application Content</div>;
const SiteAdvanceApplication = () => <div className="p-4 bg-white rounded shadow">Site Advance Application Content</div>;

const formComponents = {
  'Form_A': FormA,
  'Form_B': FormB,
  // 'WORK COMPLETION REPORT': WorkCompletion,
  // 'PRESSURE TESTING REPORT': PressureTestingReport,
  // 'TESTING AND COMMISSIONING REPORT': TestingAndCommissioningReport,
  // 'DELIVERY CHALLAN': Challan,
  // 'RETURNABLE BASIS CHALLAN': Challan,
  // 'FIRE EXTINGUISHER REFILLING CERTIFICATE': ExtinguisherRefillingCertificate,
  // 'FIRE EXTINGUISHER HPT CERTIFICATE': ExtinguisherHPTCertificate,
  // 'ATTENDANCE FORM': AttendanceForm,
  // 'LEAVE APPLICATION': LeaveApplication,
  // 'MISS PUNCH APPLICATION': MissPunchForm,
  // 'LOAN APPLICATION': LoanApplication,
  // 'SALARY ADVANCE APPLICATION': SalaryAdvanceApplication,
  // 'SITE ADVANCE APPLICATION': SiteAdvanceApplication,
};

const FormCard = ({ formName, isSelected, onClick }) => (
  <motion.button
    onClick={onClick}
    className={`w-full px-5 py-3.5 rounded-lg text-left transition-all duration-200 ${
      isSelected
        ? 'bg-black text-white shadow-md'
        : 'bg-white text-gray-800 hover:bg-gray-50 shadow-sm'
    }`}
    whileHover={{ scale: 1.01 }}
    whileTap={{ scale: 0.99 }}
  >
    <div className="flex items-center space-x-3">
      <FileText className={`w-4 h-4 ${isSelected ? 'text-white' : 'text-gray-500'}`} />
      <span className="font-medium text-sm">{formName.replace(/_/g, ' ')}</span>
    </div>
  </motion.button>
);

export default function FillDocuments() {
  const [selectedForm, setSelectedForm] = useState(null);
  const location = useLocation();
  const { type, reportId } = location.state || {};

  useEffect(() => {
    if (type && formComponents[type]) {
      setSelectedForm(type);
    }
  }, [type]);

  const handleFormSelect = (formName) => {
    setSelectedForm(formName === selectedForm ? null : formName);
  };

  const renderForm = () => {
    if (selectedForm) {
      const FormComponent = formComponents[selectedForm];
      return <FormComponent reportId={reportId} />;
    }
    return null;
  };

  return (
    <div className="p-4 font-['Montserrat']">
      <div className="max-w-7xl mx-auto">
        <motion.div
          className="text-center mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-2xl font-bold text-gray-900">
            {type && reportId ? 'Edit Document' : 'Document Forms'}
          </h2>
          {!type && (
            <p className="text-gray-600 text-sm mt-2">
              Select a form to begin
            </p>
          )}
        </motion.div>
        
        {!type && (
          <motion.div 
            className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {Object.keys(formComponents).map((formName, index) => (
              <motion.div
                key={formName}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <FormCard
                  formName={formName}
                  isSelected={selectedForm === formName}
                  onClick={() => handleFormSelect(formName)}
                />
              </motion.div>
            ))}
          </motion.div>
        )}

        <AnimatePresence mode="wait">
          {selectedForm && (
            <motion.div
              key={selectedForm}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-lg shadow-md p-6"
            >
              {renderForm()}
            </motion.div>
          )}
        </AnimatePresence>

        {!selectedForm && !type && (
          <motion.div
            className="text-center mt-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <p className="text-gray-500 text-sm">
              Please select a form to begin
            </p>
          </motion.div>
        )}
      </div>
    </div>
  );
}